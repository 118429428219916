import { RouterProvider } from "react-router-dom";
import routers from "./router/routes";
// import AutoLogout from "./components/AutoLogout";
import "./App.css";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || ""
);

export default function App() {
  return (
    <Elements stripe={stripePromise}>
      <RouterProvider router={routers} />;
      {/* <AutoLogout /> */}
    </Elements>
  );
}
