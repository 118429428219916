import { BalanceData, BankAccountData, CardData, UserData } from "../../types/auth";
import { TransactionData } from "../../types/transaction";
import Api from "../axios";

interface ProfileServiceResponse {}

export const ProfileService = {
  saveInformation: async (arg: UserData): Promise<ProfileServiceResponse> => {
    const response = await Api.post("/account/save-personal", {
      ...arg,
    });

    const data = response?.data;

    return {
      ...data,
    };
  },

  saveJobs: async (arg: UserData): Promise<ProfileServiceResponse> => {
    const response = await Api.post("/account/save-jobs", {
      ...arg,
    });

    const data = response?.data;

    return {
      ...data,
    };
  },

  saveNickname: async (arg: UserData): Promise<ProfileServiceResponse> => {
    const response = await Api.post("/account/save-nickname", {
      ...arg,
    });

    const data = response?.data;

    return {
      ...data,
    };
  },

  updateAccount: async (param: {
    email: string;
    phoneNumber: string;
    password?: string;
  }): Promise<{ token: string }> => {
    const response = await Api.post("/account/update-account", param);

    const data = response?.data;

    return {
      ...data,
    };
  },

  sendSmsCode: async (phoneNumber: string): Promise<ProfileServiceResponse> => {
    const response = await Api.post("/notification/send-sms-code", {
      phoneNumber,
    });

    const data = response?.data;

    return {
      ...data,
    };
  },

  uploadPhoto: async (formData: FormData): Promise<{ imageUrl: string }> => {
    const response = await Api.post("/account/upload-photo", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const data = response?.data;

    return {
      ...data,
    };
  },

  checkPromoCode: async (promoCode: string): Promise<{ isValid: boolean }> => {
    const response = await Api.post("/account/check-promocode", {
      promoCode,
    });
    console.log(response);
    const data = response?.data;

    return {
      ...data,
    };
  },

  activateAccount: async (): Promise<{ url: string }> => {
    const response = await Api.post("/stripe/activate-account");

    const data = response?.data;

    return {
      ...data,
    };
  },

  sendNotification: async (): Promise<object> => {
    const response = await Api.post("/notification/send-notification");

    const data = response?.data;

    return {
      ...data,
    };
  },

  verifySmsCode: async (
    verificationCode: string
  ): Promise<{ status: string }> => {
    const response = await Api.post("/notification/verify-sms-code", { verificationCode });

    const data = response?.data;

    return {
      ...data,
    };
  },

  deleteExternalAccount: async (
    account_id: string,
    external_account_id: string
  ): Promise<object> => {
    const response = await Api.post("/stripe/delete-external-account", {
      account_id,
      external_account_id,
    });

    const data = response?.data;

    return {
      ...data,
    };
  },

  getBankAccount: async (): Promise<{ data: BankAccountData[] }> => {
    const response = await Api.get("/stripe/get-bank-accounts");

    const data = response?.data;

    return {
      ...data,
    };
  },

  getCards: async (): Promise<{ data: CardData[] }> => {
    const response = await Api.get("/stripe/get-cards");

    const data = response?.data;

    return {
      ...data,
    };
  },

  getBalance: async (): Promise<BalanceData> => {
    const response = await Api.get("/stripe/get-balance");

    const data = response?.data;

    return {
      ...data,
    };
  },

  getTransactions: async (): Promise<{ data: TransactionData[] }> => {
    const response = await Api.get("/stripe/get-transactions");

    const data = response?.data;

    return {
      ...data,
    };
  },

  getTransactionsById: async (
    transactionId: string
  ): Promise<TransactionData> => {
    const response = await Api.get("/stripe/get-transaction-by-id", {
      params: { transactionId },
    });

    const data = response?.data;

    return {
      ...data,
    };
  },
};
