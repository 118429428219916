import { useState } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  StyledToggleButtonGroup,
  StyledToggleButton,
  BootstrapDialog,
} from "./styled";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { UserData } from "../types/auth";

interface SelectAmountProps {
  receiver: UserData;
  setTipAmount: (newAmount: number) => void;
  setConfirmed: (value: boolean) => void;
}

function SelectAmount({
  receiver,
  setTipAmount,
  setConfirmed,
}: SelectAmountProps) {
  const [customAmount, setCustomAmount] = useState(1);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newAmount: number
  ) => {
    if (newAmount) {
      setTipAmount(newAmount);
    }
    setConfirmed(true);
  };
  const handleContinue = () => {
    if (customAmount) {
      setTipAmount(customAmount);
      setConfirmed(true);
    }
    handleClose();
  };

  return (
    <>
      <Box sx={{ textAlign: "center", mt: { xs: 12, md: 0 } }}>
        <Typography fontWeight={600} color="secondary">
          Select tip amount
        </Typography>
        <StyledToggleButtonGroup
          exclusive
          onChange={handleChange}
          sx={{ my: 3 }}
        >
          {receiver?.tipAmounts &&
            receiver?.tipAmounts.map((item: number) => (
              <StyledToggleButton value={item} key={item}>
                <Typography color="secondary" fontSize={20}>
                  ${item}
                </Typography>
              </StyledToggleButton>
            ))}
        </StyledToggleButtonGroup>
        <Button variant="text" size="small" onClick={handleClickOpen}>
          Enter custom tip
        </Button>
      </Box>
      <BootstrapDialog
        onClose={handleClose}
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Custom Tip Amount</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            zIndex: 1001,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <TextField
            autoFocus
            type="number"
            variant="outlined"
            placeholder="Enter tip amount"
            fullWidth
            size="small"
            inputProps={{
              min: 1,
              max: 100,
              style: {
                color: "#334155",
                border: "0.5px solid rgba(0, 0, 0, 0.10)",
                borderRadius: "4px",
                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
              },
            }}
            onChange={(e) =>
              setCustomAmount(
                e.target.value !== "" ? parseInt(e.target.value) : 0
              )
            }
          />
          <Box
            sx={{
              mt: 0.5,
              fontSize: 12,
              display: "flex",
              alignItems: "center",
            }}
          >
            <ErrorOutlineIcon fontSize="inherit" color="primary" />
            <Typography color="primary" sx={{ fontSize: 12, ml: 0.5 }}>
              Tip amount must be more than $1 and less than $100.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" size="small" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={handleContinue}
            disabled={customAmount < 1 || customAmount > 100}
          >
            Continue
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export default SelectAmount;
