import { useState } from "react";
import { StyledButton, StyledTextField } from "../components/styled";
import Logo from "../assets/images/logo.svg";
import { Alert, Box, Link, Snackbar, Typography } from "@mui/material";
import { setAuthorizationToken } from "../utils";
import { AuthService } from "../api/auth/auth.service";
import { useNavigate } from "react-router-dom";

function SignUp() {
  const navigate = useNavigate();
  const [credential, setCredential] = useState({
    email: { value: "", helperText: "" },
    password: { value: "", helperText: "" },
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [open, setOpen] = useState(false);

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleSignUp = async () => {
    const passwordPattern =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!])(?!.*\s).{9,}$/;
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    let isValid = true;
    if (!passwordPattern.test(credential.password.value)) {
      setCredential((prev) => ({
        ...prev,
        password: {
          value: prev.password.value,
          helperText: "Password is not valid.",
        },
      }));
      isValid = false;
    }
    if (
      credential.password.value !== "" &&
      credential.password.value !== confirmPassword
    ) {
      setCredential((prev) => ({
        ...prev,
        password: {
          value: prev.password.value,
          helperText:
            "Confirmation password doesn't match. Please input your password again.",
        },
      }));
      isValid = false;
    }
    if (!emailPattern.test(credential.email.value)) {
      setCredential((prev) => ({
        ...prev,
        email: {
          value: prev.email.value,
          helperText: "Email is not valid.",
        },
      }));
      isValid = false;
    }
    if (!isValid) {
      return;
    }

    try {
      const data = await AuthService.signup(
        credential.email.value.trim().toLowerCase(),
        credential.password.value
      );
      console.log(data);
      setAuthorizationToken(data.token);
      navigate("/onboarding/personal-information");
    } catch (err) {
      console.log(err);
      setOpen(true);
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCredential((prev) => ({
      ...prev,
      [e.target.name]: { value: e.target.value, helperText: "" },
    }));
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          severity="error"
          sx={{ width: "100%" }}
        >
          This email already exists. Please try to log in instead.
        </Alert>
      </Snackbar>
      <img src={Logo} width={200} alt="logo" style={{ display: "block" }} />
      <Typography variant="h5" mt={3}>
        Create an account
      </Typography>
      <Typography mt={1} color="secondary">
        Enter your account details below to start collecting tips
      </Typography>
      <Box mt={4}>
        <Box mb={2}>
          <Typography variant="body2" fontWeight={600} mb={0.5}>
            Email
          </Typography>
          <StyledTextField
            id="email"
            name="email"
            placeholder="Email"
            variant="outlined"
            fullWidth
            error={!!credential.email.helperText}
            helperText={credential.email.helperText}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2}>
          <Typography variant="body2" fontWeight={600} mb={0.5}>
            Password
          </Typography>
          <StyledTextField
            type="password"
            id="password"
            name="password"
            placeholder="Password"
            variant="outlined"
            fullWidth
            error={!!credential.password.helperText}
            helperText={credential.password.helperText}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={4}>
          <Typography variant="body2" fontWeight={600} mb={0.5}>
            Confirm Password
          </Typography>
          <StyledTextField
            type="password"
            id="confirm-password"
            name="confirm-password"
            placeholder="Confirm Password"
            variant="outlined"
            fullWidth
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <ul
            style={{
              color: "#6B596B",
              fontSize: "12px",
              paddingLeft: "20px",
              marginTop: "4px",
            }}
          >
            <li>Must be more than 8 characters</li>
            <li>
              Must include at least one uppercase letter, number and special
              character
            </li>
          </ul>
        </Box>
        <Box>
          <StyledButton variant="contained" fullWidth onClick={handleSignUp}>
            Sign Up
          </StyledButton>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 4,
        }}
      >
        <Typography variant="body2" color="secondary" mr={3}>
          Already have an account?
        </Typography>
        <Link
          href="/signin"
          sx={{ textDecoration: "none", fontSize: "14px", fontWeight: 600 }}
        >
          Sign In
        </Link>
      </Box>
    </>
  );
}

export default SignUp;
