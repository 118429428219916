import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { BootstrapDialog } from "./styled";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ToSDoc from "../assets/docs/2023-12-20 Terms of Service.pdf";
import PrivacyPolicyDoc from "../assets/docs/2023-12-20 Privacy Policy.pdf";
import { UserData } from "../types/auth";
import { StripeService } from "../api/stripe/stripe.service";

interface ConfirmAmountProps {
  receiver: UserData;
  tipAmount: number;
  setConfirmed: (confirm: boolean) => void;
}

function ConfirmAmount({
  receiver,
  tipAmount,
  setConfirmed,
}: ConfirmAmountProps) {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [coverFee, setCoverFee] = useState(1);
  const fee = Math.ceil(tipAmount / 10);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handlePayment = async () => {
    const res = await StripeService.createCheckoutSession(
      tipAmount + coverFee * fee,
      fee,
      receiver.id || ""
    );
    window.location.hash = res.url;
  };
  const handleCoverFee = () => {
    setCoverFee(1);
    handleClose();
  };
  const handleDeductFee = () => {
    setCoverFee(0);
    handleClose();
  };

  return (
    <>
      <Box>
        <Button
          variant="text"
          size="small"
          onClick={() => setConfirmed(false)}
          startIcon={<ArrowBackIcon />}
          sx={{ mb: 1 }}
        >
          Change amount
        </Button>
        <Typography color="secondary" sx={{ fontWeight: 600, mb: 1 }}>
          Your tip
        </Typography>
        <Typography variant="h2">${tipAmount.toFixed(2)}</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" color="secondary" mr={1}>
            + ${(coverFee * fee).toFixed(2)} processing fee
          </Typography>
          <IconButton
            size="small"
            onClick={handleClickOpen}
            sx={{ fontSize: 14 }}
          >
            <ErrorOutlineIcon fontSize="inherit" color="primary" />
          </IconButton>
        </Box>

        <Typography color="secondary" sx={{ fontWeight: 600, mt: 6, mb: 1 }}>
          Will be sent to
        </Typography>
        <Box
          sx={{
            p: 2,
            display: "flex",
            alignItems: "center",
            borderRadius: "16px",
            backgroundColor: "#FFFFFF",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <img
            src={receiver?.photoURL}
            width={48}
            height={48}
            style={{ borderRadius: "100%", objectFit: "cover" }}
            alt="avatar"
          />
          <Box ml={1}>
            <Typography variant="subtitle1">{receiver?.nickname}</Typography>
            {receiver?.jobs && receiver?.jobs.length > 0 && (
              <Typography variant="body2" color="secondary">
                {receiver?.jobs[0].title}
              </Typography>
            )}
          </Box>
        </Box>

        <FormControlLabel
          sx={{ mt: 6 }}
          control={
            <Checkbox
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />
          }
          label={
            <>
              <Typography sx={{ color: "#334155" }}>
                I acknowledge that this transaction is non-refundable and is not
                for the sale of goods or service. Additionally, I have read and
                accepted Tipmate’s{" "}
                <Link
                  href={ToSDoc}
                  target="_blank"
                  sx={{ textDecoration: "none", fontWeight: 600 }}
                >
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link
                  href={PrivacyPolicyDoc}
                  target="_blank"
                  sx={{ textDecoration: "none", fontWeight: 600 }}
                >
                  Privacy Policy
                </Link>
                .
              </Typography>
            </>
          }
        />
        <Button
          sx={{ mt: 6 }}
          variant="contained"
          fullWidth
          endIcon={<ArrowForwardIcon />}
          disabled={!checked}
          onClick={handlePayment}
        >
          Continue to Payment
        </Button>
      </Box>
      <BootstrapDialog
        onClose={handleClose}
        open={open}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Processing fee</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            zIndex: 1001,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography color="#0F172A">
            Our mission is to pass all tips directly to those who need it. Are
            you willing to absorb the processing fee, so {receiver?.nickname}{" "}
            can receive the full tip?
          </Typography>
          <Typography sx={{ fontWeight: 600, textAlign: "center", mt: 3 }}>
            Processing fee is $1 for every $10 tipped
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" size="small" onClick={handleDeductFee}>
            Deduct fee from tip
          </Button>
          <Button variant="contained" size="small" onClick={handleCoverFee}>
            Cover fees
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export default ConfirmAmount;
