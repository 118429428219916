// third-party
import { combineReducers } from 'redux';

// project import
import account from './account';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ account });

export type AppState = ReturnType<typeof reducers>;

export default reducers;
