import { Box, Link, TextField, Typography } from "@mui/material";
import { StyledTextarea } from "../styled";
import CustomAutoInput, { OptionType } from "../CustomAutoInput";
import jobs from "../../constants/jobs.json";

export interface IPersonalInfo {
  name: string;
  photoURL: string;
  jobs: OptionType[];
  message: string;
  bio: string;
  tipAmounts: number[];
}
interface PersonalInformationProps {
  data: IPersonalInfo;
  setData: (data: IPersonalInfo) => void;
}

function PersonalInformation({ data, setData }: PersonalInformationProps) {
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const amounts = [...data.tipAmounts];
    amounts[index] = Number(e.target.value);
    setData({ ...data, tipAmounts: amounts });
  };

  return (
    <>
      <Typography variant="subtitle1" mb={0.5}>
        Profile
      </Typography>
      <Typography variant="body2" color="#64748B" mb={3}>
        If you need to make changes to your Name or Profile Picture contact{" "}
        <Link
          href="mailto:support@tipmate.co"
          sx={{ fontSize: "inherit", textDecoration: "none", color: "#A000BF" }}
        >
          support@tipmate.co
        </Link>
      </Typography>
      <Box mb={3}>
        <Typography fontSize={14} fontWeight={600} color="#475569" mb={1}>
          Name
        </Typography>
        <Typography variant="body1" fontWeight={600}>
          {data.name}
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography fontSize={14} fontWeight={600} color="#475569" mb={1}>
          Tipping Badge Photo
        </Typography>
        <img
          src={data.photoURL}
          width={64}
          height={64}
          alt="avatar"
          style={{
            borderRadius: "100%",
            border: "1px solid #E2E8F0",
            objectFit: "cover",
          }}
        />
      </Box>
      <Box mb={3}>
        <Typography fontSize={14} fontWeight={600} color="#475569" mb={1}>
          Job
        </Typography>
        <CustomAutoInput
          options={jobs}
          selected={data.jobs}
          setSelected={(value: OptionType[]) =>
            setData({ ...data, jobs: value })
          }
        />
      </Box>
      <Box mb={3}>
        <Typography fontSize={14} fontWeight={600} color="#475569" mb={1}>
          Personal Message
        </Typography>
        <StyledTextarea
          minRows={4}
          maxLength={100}
          value={data.message}
          placeholder="This is what people will see when they send you a tip."
          onChange={(e) => setData({ ...data, message: e.target.value })}
        />
      </Box>
      <Box mb={3}>
        <Typography fontSize={14} fontWeight={600} color="#475569" mb={1}>
          About Me
        </Typography>
        <StyledTextarea
          minRows={4}
          maxLength={225}
          value={data.bio}
          placeholder="This is what people will see when they are on your profile, here’s a good place to write about what motivates you such as providing for your family or saving up for your first house."
          onChange={(e) => setData({ ...data, bio: e.target.value })}
        />
      </Box>
      <Box mb={3}>
        <Typography fontSize={14} fontWeight={600} color="#475569" mb={1}>
          Change preset tip amount
        </Typography>
        <Box mt={2}>
          {[...Array(3)].map((_value, index) => (
            <TextField
              key={index}
              type="number"
              size="small"
              value={data.tipAmounts[index] || ""}
              sx={{
                mx: { md: 1.5, xs: 0 },
                width: "56px",
                [`& fieldset`]: {
                  borderRadius: "12px",
                },
              }}
              inputProps={{
                style: { textAlign: "center" },
                max: 50,
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e, index)
              }
            />
          ))}
        </Box>
      </Box>
    </>
  );
}

export default PersonalInformation;
