import { Button, Typography } from "@mui/material";

interface TransferMethodButtonProps {
  title: string;
  fee: string;
  selected: boolean;
  onClick: () => void;
}

const TransferMethodButton = ({
  title,
  fee,
  selected,
  onClick,
}: TransferMethodButtonProps) => {
  return (
    <Button
      fullWidth
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 1,
        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
        backgroundColor: selected ? "none" : "#fff",
      }}
      variant={selected ? "contained" : "text"}
      onClick={onClick}
    >
      <Typography sx={{ color: selected ? "#FFF" : "#334155", mb: 0.5 }}>
        {title}
      </Typography>
      <Typography
        sx={{ fontSize: 12, color: selected ? "#E2E8F0" : "#64748B" }}
      >
        Fee: {fee}
      </Typography>
    </Button>
  );
};

export default TransferMethodButton;
