import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { PhoneInput, StyledButton, StyledTextField } from "../styled";
import ReactPhoneInput from "react-phone-input-material-ui";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { setAuthorizationToken } from "../../utils";
import { ProfileService } from "../../api/profile/profile.service";
import { AppState } from "../../store/reducers";
import { UserData } from "../../types/auth";

function AccountSettings() {
  const user = useSelector<AppState, UserData | null>(
    ({ account }) => account.user
  );
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openPhoneVerif, setOpenPhoneVerif] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [data, setData] = useState({
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    currentPassword: "",
  });
  const [validation, setValidation] = useState({
    email: "",
    password: "",
    currentPassword: "",
  });
  const [code, setCode] = useState(new Array(6).fill(""));
  const [isPhoneNumberChanging, setPhoneNumberChanging] = useState(false);
  const inputRef = useRef<HTMLElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (e: any) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    if (e.target.name === "email") {
      setValidation((prev) => ({
        ...prev,
        email: "",
      }));
    }
    if (e.target.name === "password" || e.target.name === "confirmPassword") {
      setValidation((prev) => ({
        ...prev,
        password: "",
      }));
    }
    if (e.target.name === "currentPassword") {
      setValidation((prev) => ({
        ...prev,
        currentPassword: "",
      }));
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSavePhoneNumber = async () => {
    if (data.phoneNumber === user?.phoneNumber) {
      setPhoneNumberChanging(false);
    } else {
      setOpenPhoneVerif(true);
      try {
        await ProfileService.sendSmsCode(data.phoneNumber);
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handleCancelPhoneNumber = () => {
    setData((prev) => ({ ...prev, phoneNumber: user?.phoneNumber || "" }));
    setPhoneNumberChanging(false);
  };
  const handleVerifyPhoneNumber = async () => {
    try {
      setProcessing(true);
      const data = await verifySMSCode();
      console.log(data);
      if (data && data.status === "approved") {
        setPhoneNumberChanging(false);
        setOpenPhoneVerif(false);
      } else {
        setCode(new Array(6).fill(""));
        setFocus();
      }
      setProcessing(false);
    } catch (err) {
      console.log(err);
      setProcessing(false);
    }
  };
  const validateForm = () => {
    let isValid = true;
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const passwordPattern =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!])(?!.*\s).{9,}$/;
    if (!emailPattern.test(data.email)) {
      setValidation((prev) => ({
        ...prev,
        email: "Email is not valid.",
      }));
      isValid = false;
    }
    if (data.password !== "" && !passwordPattern.test(data.password)) {
      setValidation((prev) => ({
        ...prev,
        password: "Password is not valid.",
      }));
      isValid = false;
    }
    if (data.password !== data.confirmPassword) {
      setValidation((prev) => ({
        ...prev,
        password: "Password and Confirm Password do not match!",
      }));
      isValid = false;
    }
    return isValid;
  };
  const openConfirmPassword = () => {
    const isValid = validateForm();
    if (isValid) {
      setOpen(true);
    }
  };
  const handleSaveChanges = async () => {
    try {
      setProcessing(true);
      // const response = await AuthService.login(
      //   user.email,
      //   data.currentPassword
      // );
      let param;
      if (data.password !== "") {
        param = {
          email: data.email,
          phoneNumber: data.phoneNumber,
          password: data.password,
        };
      } else {
        param = {
          email: data.email,
          phoneNumber: data.phoneNumber,
        };
      }
      const res = await ProfileService.updateAccount(param);
      setAuthorizationToken(res.token);
      setProcessing(false);
      setOpen(false);
      setOpenAlert(true);
    } catch (e) {
      console.log(e);
      setProcessing(false);
      setValidation((prev) => ({
        ...prev,
        currentPassword: "The provided password is incorrect!",
      }));
    }
  };
  const handleCodeChange = (value: string, index: number) => {
    if (value === " " || isNaN(Number(value))) {
      if (inputRef?.current) {
        const element = inputRef.current.children[index].querySelector(
          "input"
        ) as HTMLInputElement;
        element.value = "";
      }
      return;
    }
    setCode((prev) => {
      const t = [...prev];
      t[index] = value;
      return t;
    });
    if (inputRef?.current && index < 5 && value !== "") {
      const element = inputRef.current.children[index + 1].querySelector(
        "input"
      ) as HTMLInputElement;
      element.focus();
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePaste = async (e: any) => {
    e.preventDefault();
    let clipboard = await navigator.clipboard.readText();
    clipboard = clipboard.slice(0, 6);
    const arr = Array.from(clipboard).filter(
      (x) => Number(x) > -1 && Number(x) < 10
    );
    arr.forEach((elem: string, index: number) => {
      if (inputRef?.current) {
        const element = inputRef.current.children[index].querySelector(
          "input"
        ) as HTMLInputElement;
        element.value = elem;
      }
      setCode((prev) => {
        const t = [...prev];
        t[index] = elem;
        return t;
      });
    });
  };
  const verifySMSCode = async () => {
    const codeStr = code.join("");
    if (codeStr.length < 6) return;
    try {
      const response = await ProfileService.verifySmsCode(codeStr);
      return response;
    } catch (err) {
      console.log(err);
    }
    return null;
  };
  const setFocus = () => {
    if (inputRef?.current) {
      const element = inputRef.current.children[0].querySelector(
        "input"
      ) as HTMLInputElement;
      element.focus();
    }
  };

  useEffect(() => {
    if (user) {
      setData({
        email: user.email || "",
        phoneNumber: user.phoneNumber || "",
        password: "",
        confirmPassword: "",
        currentPassword: "",
      });
    }
  }, [user]);
  useEffect(() => {
    if (code.filter((x) => parseInt(x) > -1 && parseInt(x) < 10).length === 6) {
      handleVerifyPhoneNumber();
    }
  }, [code]);
  useEffect(() => {
    setTimeout(() => {
      setFocus();
    }, 0);
  }, [openPhoneVerif]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openAlert}
        autoHideDuration={6000}
        onClose={() => setOpenAlert(false)}
      >
        <Alert
          onClose={() => setOpenAlert(false)}
          variant="filled"
          severity="success"
          sx={{ width: "100%" }}
        >
          Your personal information was updated successfully!
        </Alert>
      </Snackbar>
      <Typography variant="subtitle1" mb={2}>
        Personal Information
      </Typography>
      <Box mb={3}>
        <Typography variant="body2" fontWeight={600} mb={0.5}>
          Email
        </Typography>
        <StyledTextField
          id="email"
          name="email"
          value={data.email}
          placeholder="Email"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          error={!!validation.email}
          helperText={validation.email}
        />
      </Box>
      <Box mb={3}>
        <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 0.5 }}>
          Phone Number
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ReactPhoneInput
            country="us"
            label=""
            value={data.phoneNumber}
            onChange={(value) =>
              handleChange({
                target: { name: "phoneNumber", value },
              })
            }
            component={PhoneInput}
            containerStyle={{ flex: 1 }}
            buttonStyle={{ left: "10px" }}
            disabled={!isPhoneNumberChanging}
            inputProps={{
              name: "phoneNumber",
              error: !data.phoneNumber,
            }}
          />
          {isPhoneNumberChanging ? (
            <Box>
              <Button
                variant="text"
                sx={{ fontSize: 14, ml: 1 }}
                onClick={handleSavePhoneNumber}
              >
                Save
              </Button>
              <Button
                variant="text"
                sx={{ fontSize: 14, ml: 1 }}
                onClick={handleCancelPhoneNumber}
              >
                Cancel
              </Button>
            </Box>
          ) : (
            <Button
              variant="text"
              sx={{ fontSize: 14, ml: 1 }}
              onClick={() => setPhoneNumberChanging((prev) => !prev)}
            >
              Change
            </Button>
          )}
        </Box>
      </Box>
      <Box mb={4}>
        <Typography variant="body2" fontWeight={600} mb={0.5}>
          New Password
        </Typography>
        <StyledTextField
          type="password"
          id="password"
          name="password"
          placeholder="Password"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          error={!!validation.password}
          helperText={validation.password}
        />
        <ul
          style={{
            color: "#6B596B",
            fontSize: "12px",
            paddingLeft: "20px",
            marginTop: "4px",
          }}
        >
          <li>Must be more than 8 characters</li>
          <li>
            Must include at least one uppercase letter, number and special
            character
          </li>
        </ul>
      </Box>
      <Box mb={4}>
        <Typography variant="body2" fontWeight={600} mb={0.5}>
          Confirm Password
        </Typography>
        <StyledTextField
          type="password"
          id="confirm-password"
          name="confirmPassword"
          placeholder="Confirm Password"
          variant="outlined"
          fullWidth
          onChange={handleChange}
        />
      </Box>
      <Box>
        <StyledButton
          variant="contained"
          sx={{ fontSize: 14, padding: "10px 16px" }}
          onClick={openConfirmPassword}
        >
          Save Changes
        </StyledButton>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">
          Do you want to save changes?
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" mb={0.5}>
            Please input the current password if you want to save changes.
          </Typography>
          <StyledTextField
            type="password"
            id="current-password"
            name="currentPassword"
            placeholder="Password"
            variant="outlined"
            fullWidth
            onChange={handleChange}
            error={!!validation.currentPassword}
            helperText={validation.currentPassword}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSaveChanges} disabled={processing}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openPhoneVerif} onClose={() => setOpenPhoneVerif(false)}>
        <DialogTitle id="alert-dialog-title">
          Phone Number Verification
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            We sent a 6 digit code to your mobile phone, enter it below to
            continue.
          </Typography>
          <Box
            ref={inputRef}
            sx={{
              mt: 4,
              display: "flex",
              justifyContent: { md: "center", xs: "space-between" },
            }}
          >
            {[...Array(6)].map((_value, index) => (
              <TextField
                key={index}
                type="number"
                size="small"
                value={code[index]}
                sx={{
                  mx: { md: 1.5, xs: 0 },
                  width: "44px",
                  [`& fieldset`]: {
                    borderRadius: "12px",
                  },
                }}
                inputProps={{
                  style: { textAlign: "center" },
                  maxLength: 1,
                }}
                onChange={(e) => handleCodeChange(e.target.value, index)}
                onPaste={handlePaste}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleVerifyPhoneNumber} disabled={processing}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AccountSettings;
