import Api from "../axios";

export const StripeService = {
  createCheckoutSession: async (
    amount: number,
    fee: number,
    receiverId: string
  ): Promise<{ url: string }> => {
    const response = await Api.post("/stripe/create-checkout-session", {
      amount,
      fee,
      receiverId,
    });

    const data = response.data;

    return {
      ...data,
    };
  },

  payout: async (
    amount: string,
    destination: string,
    method: string
  ): Promise<{ url: string }> => {
    const response = await Api.post("/stripe/payout", {
      amount,
      destination,
      method,
    });

    const data = response.data;

    return {
      ...data,
    };
  },

  createStripeAccount: async (): Promise<object> => {
    const response = await Api.post("/stripe/create-stripe-account");

    const data = response.data;

    return {
      ...data,
    };
  },

  verify: async (): Promise<{ url: string }> => {
    const response = await Api.post("/stripe/verify");

    const data = response.data;

    return {
      ...data,
    };
  },

  getCheckoutSession: async (
    sessionId: string
  ): Promise<{ data: { amount_total: number } }> => {
    const response = await Api.get("stripe/checkout-session", {
      params: { sessionId },
    });

    const data = response.data;

    return {
      ...data,
    };
  },
};
