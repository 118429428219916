// types
import { createSlice } from "@reduxjs/toolkit";
import { UserData } from "../../types/auth";

// initial state
const initialState: {
  isLoading: boolean;
  isLoggedIn: boolean;
  keepSignedIn: boolean;
  user: UserData | null;
  error: null;
  timer: number;
} = {
  isLoading: false,
  isLoggedIn: false,
  keepSignedIn: false,
  user: null,
  error: null,
  timer: 15,
};

// ==============================|| SLICE - ACCOUNT ||============================== //

const account = createSlice({
  name: "account",
  initialState,
  reducers: {
    loginSuccess(state, action) {
      state.isLoggedIn = true;
      state.isLoading = false;
      state.error = null;
      if (action.payload) {
        state.keepSignedIn = action.payload.keepSignedIn;
      }
    },
    logout(state, _action) {
      state.isLoggedIn = false;
      state.isLoading = false;
      state.user = null;
      state.error = null;
    },
    updateUser(state, action) {
      state.isLoggedIn = true;
      state.isLoading = false;
      state.error = null;
      if (action.payload) {
        state.user = action.payload;
      }
    },
    resetTimer(state, _action) {
      state.timer = initialState.timer;
    },
  },
});

export default account.reducer;

export const { loginSuccess, logout, updateUser, resetTimer } = account.actions;
