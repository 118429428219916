import {
  useAutocomplete,
  AutocompleteGetTagProps,
} from "@mui/base/useAutocomplete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { AutocompleteGroupedOption } from "@mui/material/useAutocomplete";
import { Typography } from "@mui/material";

const Root = styled("div")`
  color: "rgba(0,0,0,.85)";
  font-size: 14px;
`;

const InputWrapper = styled("div")`
  width: 100%;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 12px;
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);

  &:hover {
    border-color: #40a9ff;
  }

  &.focused {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: #fff;
    color: "rgba(0,0,0,.85)";
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    border-radius: 12px;
    margin: 0;
    outline: 0;
  }
`;

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
}

function Tag(props: TagProps) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

const StyledTag = styled(Tag)<TagProps>`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 4px;
  font-size: 12px;
  line-height: 22px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`;

const Listbox = styled("ul")`
  width: 350px;
  margin: 2px 0 0;
  padding: 5px 12px;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & ul {
    padding: 0;
    & li {
      padding: 8px 12px;
      margin-top: 8px;
      display: flex;

      & span {
        flex-grow: 1;
      }

      & svg {
        color: transparent;
      }
    }

    & li[aria-selected="true"] {
      background-color: #fafafa;
      font-weight: 600;

      & svg {
        color: #1890ff;
      }
    }

    & li.${autocompleteClasses.focused} {
      background-color: #e6f7ff;
      cursor: pointer;

      & svg {
        color: currentColor;
      }
    }
  }
`;

interface CustomAutoInputProps {
  options: OptionType[];
  selected: OptionType[];
  setSelected: Function;
}

export interface OptionType {
  group: string;
  title: string;
}

export default function CustomAutoInput({
  options,
  selected,
  setSelected,
}: CustomAutoInputProps) {
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook-demo",
    value: selected,
    multiple: true,
    options: options,
    getOptionLabel: (option) => option.title || option.group,
    groupBy: (option) => option.group,
    isOptionEqualToValue: (option, value) =>
      option.group === value.group && option.title === value.title,
    onChange: (_event, value, _reason, _details) => {
      setSelected(value);
    },
  });

  return (
    <Root>
      <div {...getRootProps()}>
        <InputWrapper ref={setAnchorEl} className={focused ? "focused" : ""}>
          {value.map((option: OptionType, index: number) => (
            <StyledTag label={option.title} {...getTagProps({ index })} />
          ))}
          <input {...getInputProps()} />
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {(groupedOptions as AutocompleteGroupedOption<OptionType>[]).map(
            (groupOpt) => (
              <li key={groupOpt.key}>
                <Typography sx={{ fontWeight: 600, color: "#64748B", mt: 1 }}>
                  {groupOpt.group}
                </Typography>
                <ul>
                  {groupOpt.options.map((option, index) => (
                    <li
                      key={index}
                      {...getOptionProps({
                        option,
                        index: index + groupOpt.key,
                      })}
                    >
                      <Typography sx={{ fontWeight: 600 }} component="span">
                        {option.title}
                      </Typography>
                      <CheckIcon fontSize="small" />
                    </li>
                  ))}
                </ul>
              </li>
            )
          )}
        </Listbox>
      ) : null}
    </Root>
  );
}
