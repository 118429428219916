import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import OnboardingTemplate from "./OnboardingTemplate";
import { StyledTextField } from "../../components/styled";

import { ProfileService } from "../../api/profile/profile.service";
import { AppState } from "../../store/reducers";
import { Manager, UserData } from "../../types/auth";

function ManagerInformation() {
  const navigate = useNavigate();
  const user = useSelector<AppState, UserData | null>(
    ({ account }) => account.user
  );
  const [manager, setManager] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });
  const [isManager, setIsManager] = useState(false);
  const [validation, setValidation] = useState(false);
  const handleContinue = async () => {
    setValidation(true);
    if (
      !isManager &&
      (manager.name === "" ||
        manager.email === "" ||
        manager.phoneNumber === "")
    ) {
      return;
    }
    try {
      const res = await ProfileService.saveInformation({
        isManager,
        manager: isManager ? null : manager,
      });
      console.log(res);
      navigate("/onboarding/onboarding-confirmation");
    } catch (err) {
      console.log(err);
    }
  };
  const handleSkip = () => {
    navigate("/onboarding/onboarding-confirmation");
  };
  const handleBack = () => {
    navigate("/onboarding/employer-information");
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setManager((prev: Manager) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (user) {
      setIsManager(user.isManager || false);
      setManager({
        name: user.manager?.name || "",
        email: user.manager?.email || "",
        phoneNumber: user.manager?.phoneNumber || "",
      });
    }
  }, [user]);

  return (
    <OnboardingTemplate
      currentStep={6}
      title="Your Manager"
      description="This is the individual you report to at your current job."
      banner={
        <Typography
          sx={{
            fontFamily: "Darker Grotesque",
            lineHeight: "20px",
            color: "#161215",
            textAlign: "center",
          }}
        >
          TipMate is here to help you meet your goals by getting bigger tips!
        </Typography>
      }
      skippable={true}
      handleContinue={handleContinue}
      handleSkip={handleSkip}
      handleBack={handleBack}
    >
      <FormControlLabel
        control={
          <Switch
            checked={isManager}
            onChange={(e) => setIsManager(e.target.checked)}
          />
        }
        label="I am the manager."
        sx={{ mb: 3 }}
      />
      {!isManager && (
        <>
          <Box mb={3}>
            <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 0.5 }}>
              Manager Name
            </Typography>
            <StyledTextField
              name="name"
              placeholder="Enter name of your manager"
              sx={{ width: "100%" }}
              value={manager.name}
              error={validation && !manager.name}
              onChange={handleChange}
            />
          </Box>
          <Box mb={3}>
            <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 0.5 }}>
              Email
            </Typography>
            <StyledTextField
              name="email"
              type="email"
              placeholder="Enter email of your manager"
              sx={{ width: "100%" }}
              value={manager.email}
              error={validation && !manager.email}
              onChange={handleChange}
            />
          </Box>
          <Box mb={3}>
            <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 0.5 }}>
              Phone Number
            </Typography>
            <StyledTextField
              name="phoneNumber"
              type="tel"
              placeholder="Enter your manager phone number"
              sx={{ width: "100%" }}
              value={manager.phoneNumber}
              error={validation && !manager.phoneNumber}
              onChange={handleChange}
            />
          </Box>
        </>
      )}
    </OnboardingTemplate>
  );
}

export default ManagerInformation;
