// import { UserData } from "../../types/auth";
import { UserData } from "../../types/auth";
import Api from "../axios";

interface AuthServiceResponse {
  token: string;
}

export const AuthService = {
  login: async (
    email: string,
    password: string
  ): Promise<AuthServiceResponse> => {
    const response = await Api.post("/auth/login", {
      email,
      password,
    });

    const { token } = response.data;

    return {
      token,
    };
  },

  signup: async (
    email: string,
    password: string
  ): Promise<AuthServiceResponse> => {
    const response = await Api.post("/auth/signup", {
      email,
      password,
    });

    const { token } = response.data;

    return {
      token,
    };
  },

  resetPassword: async (token: string, password: string): Promise<string> => {
    const response = await Api.post("/auth/reset-password", {
      token,
      password,
    });

    const data = response.data;

    return {
      ...data,
    };
  },

  forgotPassword: async (email: string): Promise<AuthServiceResponse> => {
    const response = await Api.post("/auth/forgot-password", {
      email,
    });

    const { token } = response.data;

    return {
      token,
    };
  },

  createExternalAccount: async (
    token: string
  ): Promise<AuthServiceResponse> => {
    const response = await Api.post("/stripe/create-external-account", {
      token,
    });

    const data = response.data;

    return {
      ...data,
    };
  },

  createExternalAccountCard: async (
    token: string
  ): Promise<AuthServiceResponse> => {
    const response = await Api.post("/stripe/create-external-account-card", {
      token,
    });

    const data = response.data;

    return {
      ...data,
    };
  },

  getUser: async (): Promise<UserData> => {
    const response = await Api.get("/account/get-user");

    const data = response.data;

    return {
      ...data,
    };
  },

  getUserById: async (receiverId: string): Promise<UserData> => {
    const response = await Api.get("/stripe/get-user-by-id", {
      params: { id: receiverId },
    });

    const data = response.data;

    return {
      ...data,
    };
  },
};
