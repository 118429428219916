import { Box, Paper, Typography } from "@mui/material";
import { StyledChip } from "./styled";

function RewardsCard() {
  return (
    <Paper sx={{ mt: 2, p: 2, borderRadius: "16px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="body2" color="secondary">
          TipMate Rewards
        </Typography>
        <StyledChip
          label={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography fontSize={12} lineHeight="16px">
                Coming soon
              </Typography>
            </Box>
          }
          sx={{
            backgroundColor: "#F43F5E",
            color: "#fff",
          }}
        />
      </Box>
      <Typography
        sx={{
          fontSize: 64,
          lineHeight: "60px",
          fontFamily: "Darker Grotesque",
        }}
      >
        0
      </Typography>
    </Paper>
  );
}

export default RewardsCard;
