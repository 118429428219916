import { useEffect, useState } from "react";
import { Box, Button, List, Typography } from "@mui/material";
import { TransactionHistoryItem } from "./TransactionHistoryItem";
import { ProfileService } from "../api/profile/profile.service";
import { TransactionData } from "../types/transaction";

function RecentTips() {
  const [history, setHistory] = useState<TransactionData[] | null>(null);
  useEffect(() => {
    const fetchTransactionHistory = async () => {
      const res = await ProfileService.getTransactions();
      const transactions = res.data.filter(
        (x: TransactionData) => x.type === "payout" || x.type === "payment"
      );
      setHistory(transactions);
    };
    fetchTransactionHistory();
  }, []);

  return (
    <Box sx={{ mt: 4 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: 600 }}>
          Your recent tips
        </Typography>
        <Button
          variant="text"
          sx={{ p: 0.5, fontSize: 12, lineHeight: "16px" }}
          href="/transactions"
        >
          View all
        </Button>
      </Box>
      {history === null ? (
        <Typography variant="body2" sx={{ mt: 2, mb: 4, mx: 2 }}>
          Loading...
        </Typography>
      ) : history.length > 0 ? (
        <List>
          {history.slice(0, 5).map((item: TransactionData) => (
            <TransactionHistoryItem key={item.id} data={item} />
          ))}
        </List>
      ) : (
        <Typography
          variant="body2"
          sx={{ mt: 2, mb: 4, mx: 2, fontStyle: "italic" }}
        >
          No transactions yet.
        </Typography>
      )}
    </Box>
  );
}

export default RecentTips;
