import { useState } from "react";
import { Box, DialogContent, IconButton, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import { BootstrapDialog } from "./styled";
import { UserData } from "../types/auth";

interface PersonalInfoProps {
  receiver: UserData;
}

function PersonalInfo({ receiver }: PersonalInfoProps) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box sx={{ textAlign: "center" }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {receiver && (
            <img
              src={receiver?.photoURL}
              width={120}
              height={150}
              style={{
                border: "1px solid #9E519F",
                borderRadius: "24px",
                objectFit: "cover",
              }}
              alt="avatar"
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 0.5,
          }}
        >
          <Typography
            sx={{
              fontSize: 30,
              fontFamily: "Darker Grotesque",
              lineHeight: "40px",
              marginRight: 1,
              marginLeft: 4,
            }}
          >
            {receiver?.nickname}
          </Typography>
          <IconButton size="small" onClick={handleClickOpen} sx={{ mt: 1 }}>
            <ErrorOutlineIcon fontSize="inherit" color="primary" />
          </IconButton>
        </Box>
        {receiver?.jobs && receiver?.jobs.length > 0 && (
          <Typography variant="body2">{receiver?.jobs[0].title}</Typography>
        )}
        {!!receiver?.message && (
          <Box
            sx={{
              display: "flex",
              position: "relative",
              mt: { md: "24px", xs: "48px" },
            }}
          >
            <Typography
              color="secondary"
              sx={{
                fontWeight: 600,
                fontSize: 120,
                opacity: 0.3,
                position: "absolute",
                left: 0,
                top: "-45px",
              }}
            >
              “
            </Typography>
            <Typography
              variant="body1"
              sx={{
                width: "100%",
                paddingX: "50px",
                textAlign: "center",
                mt: "28px",
              }}
            >
              {receiver?.message}
            </Typography>
            <Typography
              color="secondary"
              sx={{
                fontWeight: 600,
                fontSize: 120,
                opacity: 0.3,
                position: "absolute",
                right: 0,
                bottom: "-45px",
              }}
            >
              „
            </Typography>
          </Box>
        )}
      </Box>
      <BootstrapDialog
        onClose={handleClose}
        open={open}
        fullWidth
        maxWidth="xs"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            zIndex: 1001,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box sx={{ textAlign: "center" }}>
            <img
              src={receiver?.photoURL}
              width={72}
              height={72}
              alt="avatar"
              style={{ borderRadius: "100%", objectFit: "cover" }}
            />
            <Typography variant="h6" sx={{ color: "#0F172A", mt: 1, mb: 1 }}>
              {receiver?.nickname}
            </Typography>
            {receiver?.jobs && receiver?.jobs.length > 0 && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography sx={{ mr: 1, color: "#475569" }}>
                  {receiver?.jobs[0].title}
                </Typography>
              </Box>
            )}
            <Typography mt={2}>{receiver?.bio}</Typography>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}

export default PersonalInfo;
