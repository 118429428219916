import { Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface StepperProps {
  total: number;
  current: number;
}

export const Stepper = ({ total, current }: StepperProps) => {
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {[...Array(current - 1)].map((_item, index) => (
          <CheckCircleIcon
            key={`completed-${index}`}
            sx={{
              width: "18px",
              height: "18px",
              color: "#006D31",
              borderRadius: "32px",
              mr: 2,
            }}
          />
        ))}
        <Box
          sx={{
            width: "16px",
            height: "16px",
            backgroundColor: "#FF5050",
            borderRadius: "32px",
            border: "2px solid #FFDAD7",
            mr: 2,
          }}
        />
        {[...Array(total - current)].map((_item, index) => (
          <Box
            key={`todo-${index}`}
            sx={{
              width: "16px",
              height: "16px",
              border: "1px solid #94A3B8",
              borderRadius: "32px",
              mr: 2,
            }}
          />
        ))}
      </Box>
    </>
  );
};
