import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import PersonalInfo from "../../components/PersonalInfo";
import SelectAmount from "../../components/SelectAmount";
import ConfirmAmount from "../../components/ConfirmAmount";
import { AuthService } from "../../api/auth/auth.service";
import { UserData } from "../../types/auth";

function SelectTip() {
  const { receiverId } = useParams();
  const [confirmed, setConfirmed] = useState(false);
  const [tipAmount, setTipAmount] = useState(3);
  const [receiver, setReceiver] = useState<UserData | null>(null);

  useEffect(() => {
    const fetchReceiverInfo = async () => {
      const data = await AuthService.getUserById(receiverId || "");
      setReceiver(data);
    };
    fetchReceiverInfo();
  }, [receiverId]);

  return (
    <Box sx={{ mt: 4, mb: 8 }}>
      {receiver && (
        <Grid container justifyContent="center" spacing={{ xs: 0, md: 8 }}>
          <Grid
            item
            md={6}
            sx={
              confirmed
                ? { display: { xs: "none", md: "block" } }
                : { width: "100%" }
            }
          >
            <PersonalInfo receiver={receiver} />
          </Grid>
          <Grid item md={6}>
            {confirmed ? (
              <ConfirmAmount
                receiver={receiver}
                tipAmount={tipAmount}
                setConfirmed={setConfirmed}
              />
            ) : (
              <SelectAmount
                receiver={receiver}
                setTipAmount={setTipAmount}
                setConfirmed={setConfirmed}
              />
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default SelectTip;
