import { createBrowserRouter } from "react-router-dom";

import MainLayout from "../layouts/MainLayout";
import AuthLayout from "../layouts/AuthLayout";
import SecondaryLayout from "../layouts/SecondaryLayout";
import PublicLayout from "../layouts/PublicLayout";

import Dashboard from "../pages/Dashboard";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import YourBadge from "../pages/YourBadge";
import Transfer from "../pages/Transfer";
import TransferSuccess from "../pages/TransferSuccess";
import Settings from "../pages/Settings";
import Transactions from "../pages/Transactions";
import TransactionDetail from "../pages/TransactionDetail";
import Rewards from "../pages/Rewards";
import OrderHardware from "../pages/OrderHardware";
import NameAndNumber from "../pages/Onboarding/NameAndNumber";
import IndustryAndJob from "../pages/Onboarding/IndustryAndJob";
import BadgeName from "../pages/Onboarding/BadgeName";
import TwoFactor from "../pages/Onboarding/TwoFactor";
import Photo from "../pages/Onboarding/Photo";
import ConfirmPhoto from "../pages/Onboarding/ConfirmPhoto";
import EmployerInformation from "../pages/Onboarding/EmployerInformation";
import ManagerInformation from "../pages/Onboarding/ManagerInformation";
import OnboardingConfirmation from "../pages/Onboarding/OnboardingConfirmation";
import PromoCode from "../pages/Onboarding/PromoCode";
import SelectTip from "../pages/Sender/SelectTip";
import Success from "../pages/Sender/Success";
import ActivateAccount from "../pages/Activate";
import ResetPassword from "../pages/ResetPassword";

const routers = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/your-badge",
        element: <YourBadge />,
      },
      {
        path: "/transfer",
        element: <Transfer />,
      },
      {
        path: "/transfer-success",
        element: <TransferSuccess />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
      {
        path: "/transactions",
        element: <Transactions />,
      },
      {
        path: "/transaction/:transactionId",
        element: <TransactionDetail />,
      },
      {
        path: "/rewards",
        element: <Rewards />,
      },
      {
        path: "/order-hardware",
        element: <OrderHardware />,
      },
      {
        path: "/activate-account",
        element: <ActivateAccount />,
      },
    ],
  },
  {
    path: "/onboarding",
    element: <SecondaryLayout />,
    children: [
      {
        path: "personal-information",
        element: <NameAndNumber />,
      },
      {
        path: "two-factor",
        element: <TwoFactor />,
      },
      {
        path: "industry-and-job",
        element: <IndustryAndJob />,
      },
      {
        path: "badge-name",
        element: <BadgeName />,
      },
      {
        path: "photo",
        element: <Photo />,
      },
      {
        path: "confirm-photo",
        element: <ConfirmPhoto />,
      },
      {
        path: "employer-information",
        element: <EmployerInformation />,
      },
      {
        path: "manager-information",
        element: <ManagerInformation />,
      },
      {
        path: "onboarding-confirmation",
        element: <OnboardingConfirmation />,
      },
      {
        path: "promo-code",
        element: <PromoCode />,
      },
    ],
  },
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "/signin",
        element: <SignIn />,
      },
      {
        path: "/signup",
        element: <SignUp />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: "/",
    element: <PublicLayout />,
    children: [
      {
        path: "/sendtip/:receiverId",
        element: <SelectTip />,
      },
    ],
  },
  {
    path: "/sendtip/:receiverId/success",
    element: <Success />,
  },
]);

export default routers;
