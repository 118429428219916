import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import cookie from "react-cookies";
import { ProfileService } from "../api/profile/profile.service";
import { StripeService } from "../api/stripe/stripe.service";

function ActivateAccount() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const sessionId = params.get("session_id");

  useEffect(() => {
    const fetchData = async () => {
      const res = await StripeService.getCheckoutSession(sessionId || "");
      if (res.data.amount_total === 500) {
        cookie.remove("TIPMATE_ACTIVATE_ACCOUNT_URL");
        await ProfileService.saveInformation({ activated: true });
      }
      navigate("/");
    };
    fetchData();
  }, []);

  return <></>;
}

export default ActivateAccount;
