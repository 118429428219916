import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useParams } from "react-router-dom";
import { StyledChip } from "../components/styled";
import CheckIcon from "@mui/icons-material/Check";
import { convertTimestampToDateTime } from "../utils";
import { TransactionData } from "../types/transaction";
import { ProfileService } from "../api/profile/profile.service";

function TransactionDetail() {
  const { transactionId } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<TransactionData | null>(null);
  const [amount, setAmount] = useState(0);
  const [fee, setFee] = useState(0);

  const fetchTransactionHistory = async () => {
    try {
      setLoading(true);
      const transaction = await ProfileService.getTransactionsById(
        transactionId || ""
      );
      setData(transaction);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactionHistory();
  }, []);

  useEffect(() => {
    if (data && data.type === "payout") {
      // let metadata = data.metadata;
      setAmount(data?.amount || Math.abs(data.net));
      setFee(data?.fee || 0);
    }
  }, [data]);

  return (
    <>
      <Button
        sx={{ fontSize: 14, fontWeight: 600 }}
        startIcon={<ArrowBackIcon sx={{ fontSize: "16px" }} />}
        href="/transactions"
      >
        All Transactions
      </Button>
      <Typography variant="h5" mt={1}>
        Transaction Detail
      </Typography>
      {loading ? (
        <Typography mt={4}>Loading...</Typography>
      ) : (
        <>
          <Box mt={5}>
            <Typography sx={{ color: "#475569", mb: 1 }}>
              {data?.type === "payment" ? "Tip" : "Payout"} Amount
            </Typography>
            <Typography sx={{ fontSize: 30, fontWeight: 600 }}>
              $
              {(
                (data?.type === "payment" ? Math.abs(data?.net) : amount) / 100
              ).toFixed(2)}
            </Typography>
          </Box>
          {data?.type === "payout" && (
            <Box mt={5}>
              <Typography sx={{ color: "#475569", mb: 1 }}>Fee</Typography>
              <Typography sx={{ fontSize: 30, fontWeight: 600 }}>
                ${(fee / 100).toFixed(2)}
              </Typography>
            </Box>
          )}
          <Box mt={5}>
            <Typography sx={{ color: "#475569", mb: 1 }}>Status</Typography>
            {data?.status === "pending" ? (
              <StyledChip
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CheckIcon sx={{ fontSize: 16 }} />
                    <Typography fontSize={12} lineHeight="16px">
                      Pending
                    </Typography>
                  </Box>
                }
                sx={{
                  backgroundColor: "#DBEAFE",
                  color: "#3B82F6",
                  mb: 2,
                }}
              />
            ) : (
              <StyledChip
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CheckIcon sx={{ fontSize: 16 }} />
                    <Typography fontSize={12} lineHeight="16px">
                      Completed
                    </Typography>
                  </Box>
                }
                sx={{
                  backgroundColor: "#D1FAE5",
                  color: "#10B981",
                  mb: 1,
                }}
              />
            )}
          </Box>
          <Box mt={4}>
            <Typography sx={{ color: "#475569", mb: 1 }}>Tip Date</Typography>
            <Typography sx={{ fontWeight: 600 }}>
              {convertTimestampToDateTime(data?.created || Date.now())}
            </Typography>
          </Box>
          <Box mt={5}>
            <Typography sx={{ color: "#475569", mb: 1 }}>
              Transaction ID
            </Typography>
            <Typography sx={{ fontWeight: 600 }}>{data?.id}</Typography>
          </Box>
        </>
      )}
    </>
  );
}

export default TransactionDetail;
