import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router";
import { useSelector } from "react-redux";
import Header from "../components/Header";
import { ContentWrapper } from "../components/styled";
import { Box } from "@mui/material";
import ProtectedRoute from "./ProtectedRoute";
import { AppState } from "../store/reducers";
import { UserData } from "../types/auth";

const MainLayout = () => {
  const navigate = useNavigate();
  const user = useSelector<AppState, UserData | null>(
    ({ account }) => account.user
  );
  useEffect(() => {
    if (user && !user.stripeAccountId) {
      navigate("/onboarding/personal-information");
    }
  }, [user]);
  return (
    <ProtectedRoute>
      <Box sx={{ minHeight: "100vh" }}>
        <Header />
        <ContentWrapper
          sx={{
            paddingX: { md: "40px", xs: "24px" },
            paddingY: { md: "48px", xs: "24px" },
            color: "#0F172A",
          }}
        >
          <Outlet />
        </ContentWrapper>
      </Box>
    </ProtectedRoute>
  );
};

export default MainLayout;
