import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MainWrapper } from "../../components/styled";
import CheckImage from "../../assets/images/check.svg";
import { Typography } from "@mui/material";
import { UserData } from "../../types/auth";
import { AuthService } from "../../api/auth/auth.service";

function Success() {
  const [receiver, setReceiver] = useState<UserData>({ nickname: "" });
  const { receiverId } = useParams();

  useEffect(() => {
    const fetchReceiverInfo = async () => {
      const data: UserData = await AuthService.getUserById(receiverId || "");
      setReceiver(data);
    };
    fetchReceiverInfo();
  }, [receiverId]);

  return (
    <MainWrapper
      sx={{
        maxWidth: "648px !important",
        textAlign: "center",
        paddingTop: "96px",
        paddingBottom: "64px",
      }}
    >
      <img src={CheckImage} width={72} height={72} alt="check" />
      <Typography
        sx={{
          fontFamily: "Darker Grotesque",
          fontSize: 36,
          lineHeight: "44px",
          mt: 4,
        }}
      >
        Tip Successful
      </Typography>
      <Typography fontSize={18} mt={2}>
        You’ve just brightened {receiver?.nickname}’s day! We emailed you a
        record of this transaction.
      </Typography>
    </MainWrapper>
  );
}

export default Success;
