import { Button, Link, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function OrderHardware() {
  return (
    <>
      <Button
        sx={{ fontSize: 14, fontWeight: 600 }}
        startIcon={<ArrowBackIcon sx={{ fontSize: "16px" }} />}
        href="/"
      >
        Back to Dashboard
      </Button>
      <Typography mt={4}>
        Thanks for your interest in Tipmate hardware. We can customize your tip
        collecting experience with our personalized hardware. Order countertop
        tip stands, tip stickers, customized tipping name tags and much more.
      </Typography>
      <Typography mt={1}>
        Soon you'll be able to purchase hardware from this tab. For the time
        being, send us an email at{" "}
        <Link href="mailto:hardware@tipmate.co">hardware@tipmate.co</Link> to
        order.
      </Typography>
    </>
  );
}

export default OrderHardware;
