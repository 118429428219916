import { useState } from "react";
import {
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { BootstrapDialog, StyledButton, StyledSelect } from "./styled";
import BankAccount from "./walletconnect/BankAccount";
import Card from "./walletconnect/Card";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { AuthService } from "../api/auth/auth.service";

interface AddAccountDialogProps {
  open: boolean;
  handleClose: () => void;
  fetchAccounts: () => void;
}

export const AddAccountDialog = ({
  open,
  handleClose,
  fetchAccounts,
}: AddAccountDialogProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardData, setCardData] = useState({
    number: "",
    exp_year: undefined,
    exp_month: undefined,
    cvc: "",
    address_zip: "",
    currency: "usd",
    object: "card",
  });
  const [bankData, setBankData] = useState({
    country: "US",
    currency: "USD",
    routing_number: "",
    account_number: "",
    account_holder_name: "",
    account_holder_type: "individual",
    object: "bank_account",
  });
  const [validation, setValidation] = useState(false);
  const [accountType, setAccountType] = useState("bank_account");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (accountType === "card") {
      setCardData({ ...cardData, [e.target.name]: e.target.value });
    } else {
      setBankData({ ...bankData, [e.target.name]: e.target.value });
    }
  };
  const handleAdd = async () => {
    console.log(stripe, elements);
    if (!stripe || !elements) {
      return;
    }
    setValidation(true);
    if (
      accountType === "bank_account" &&
      (!bankData.routing_number || !bankData.account_number)
    ) {
      return;
    }
    try {
      let token;
      if (accountType === "card") {
        const cardNumberElement = elements.getElement("cardNumber");
        if (cardNumberElement) {
          const cardToken = await stripe.createToken(cardNumberElement, {
            currency: "usd",
            address_zip: cardData.address_zip,
            address_country: "US",
          });
          token = cardToken.token;
        }
      } else {
        const bankToken = await stripe.createToken("bank_account", bankData);
        token = bankToken.token;
      }

      if (token) {
        handleClose();
        if(accountType === "card") { 
        const res = await AuthService.createExternalAccountCard(token?.id);
        console.log(res);
        fetchAccounts();
        } else {
          const res = await AuthService.createExternalAccount(token?.id);
          console.log(res);
          fetchAccounts();
        }
      }
    } catch (err) {
      console.log(err);
      handleClose();
    }
  };
  return (
    <BootstrapDialog onClose={handleClose} open={open} fullWidth maxWidth="xs">
      <DialogTitle>Add external account</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
          zIndex: 1001,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box mb={6}>
          <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 0.5 }}>
            Bank Account / Debit Card
          </Typography>
          <StyledSelect
            value={accountType}
            fullWidth
            onChange={(e: SelectChangeEvent<unknown>) =>
              setAccountType(e.target.value as string)
            }
          >
            <MenuItem value="bank_account">Bank Account</MenuItem>
            <MenuItem value="card">Debit Card</MenuItem>
          </StyledSelect>
        </Box>
        {accountType === "bank_account" ? (
          <BankAccount
            data={bankData}
            validation={validation}
            handleChange={handleChange}
          />
        ) : (
          <Card
            data={cardData}
            validation={validation}
            handleChange={handleChange}
          />
        )}
        <Box mb={3}>
          <StyledButton variant="contained" fullWidth onClick={handleAdd}>
            Add
          </StyledButton>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
};
