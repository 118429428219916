import { Box, Typography } from "@mui/material";
import { StyledTextField } from "../styled";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import "./Card.css";
import { CardData } from "../../types/auth";

interface CardProps {
  data: CardData;
  validation: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function Card({ data, validation, handleChange }: CardProps) {
  return (
    <>
      <Box mb={3}>
        <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 0.5 }}>
          Card Number
        </Typography>
        <CardNumberElement
          options={{
            style: {
              base: {
                fontSize: "16px",
              },
            },
          }}
        />
      </Box>
      <Box sx={{ mb: 3, display: "flex" }}>
        <Box sx={{ width: "50%" }} mr={1}>
          <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 0.5 }}>
            Expire
          </Typography>
          <CardExpiryElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                },
              },
            }}
          />
        </Box>
        <Box sx={{ width: "50%" }}>
          <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 0.5 }}>
            CVC
          </Typography>
          <CardCvcElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                },
              },
            }}
          />
        </Box>
      </Box>
      <Box mb={3}>
        <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 0.5 }}>
          Zip Code
        </Typography>
        <StyledTextField
          placeholder="Zip Code"
          sx={{ width: "100%" }}
          name="address_zip"
          inputProps={{ maxLength: 5 }}
          error={validation && !data?.address_zip}
          onChange={handleChange}
        />
      </Box>
    </>
  );
}

export default Card;
