import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { StyledButton } from "../../components/styled";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import BadgeViewer from "../../components/BadgeViewer";
 
import { ProfileService } from "../../api/profile/profile.service";

function ConfirmPhoto() {
  const navigate = useNavigate();
  const handleContinue = async () => {
    try {
      await ProfileService.sendNotification();
    } catch (e) {
      console.log(e);
    }
    navigate("/onboarding/onboarding-confirmation");
    // navigate("/onboarding/employer-information");
  };

  return (
    <Box>
      <Button
        variant="outlined"
        startIcon={<ArrowBackIcon sx={{ width: 16, height: 16 }} />}
        href="/onboarding/photo"
        sx={{
          padding: "10px 16px",
          borderRadius: "12px",
          fontSize: 14,
          lineHeight: "20px",
          border: "1px solid #161215",
        }}
      >
        Back
      </Button>
      <Typography variant="h6" mt={4} mb={1}>
        Review your Badge
      </Typography>
      <Typography variant="body2" color="secondary">
        Make sure your photo and information is correct on your Tipping Badge
        before you continue.
      </Typography>
      <BadgeViewer isStatic={true} />
      <StyledButton
        variant="contained"
        fullWidth
        startIcon={<CheckIcon sx={{ width: 20, height: 20 }} />}
        onClick={handleContinue}
      >
        Looks Great
      </StyledButton>
    </Box>
  );
}

export default ConfirmPhoto;
