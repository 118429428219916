import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Paper,
  Theme,
  Typography,
} from "@mui/material";
import MoreSteps from "../components/MoreSteps";
import debitCard from "../assets/images/debitcard.png";
import YourTipsCard from "../components/YourTipsCard";
import RewardsCard from "../components/RewardsCard";
import QrCodeIcon from "@mui/icons-material/QrCode";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import PaymentsIcon from "@mui/icons-material/Payments";
import RecentTips from "../components/RecentTips";

import { StyledChip } from "../components/styled";
import { ProfileService } from "../api/profile/profile.service";
import { BalanceData } from "../types/auth";

const cardList = [
  { title: "My Badge", icon: QrCodeIcon, url: "/your-badge" },
  { title: "My Rewards", icon: PaymentsIcon, url: "#" },
  { title: "Tip Stats", icon: TrendingUpIcon, url: "#" },
  { title: "Resources", icon: HelpCenterIcon, url: "#" },
];

function Dashboard() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(true);
  const [showDebitCard, setShowDebitCard] = useState(true);
  const [balance, setBalance] = useState<BalanceData>();
  const [tipmateCard, setTipmateCard] = useState("");

  const handleClose = () => {
    setLoading(false);
  };
  const handleNotificationClose = () => {
    localStorage.setItem("TIPMATE_NOTIFICATION", "0");
    setShowNotification(false);
  };
  const handleClkInterested = () => {
    localStorage.setItem("TIPMATE_DEBIT_CARD", "1");
    setTipmateCard("interested");
  };
  const handleClkDismiss = () => {
    localStorage.setItem("TIPMATE_DEBIT_CARD", "1");
    setTipmateCard("dismiss");
  };

  const fetchBalanceData = async () => {
    try {
      setLoading(true);
      const data = await ProfileService.getBalance();
      setBalance(data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBalanceData();
    const notification = localStorage.getItem("TIPMATE_NOTIFICATION");
    setShowNotification(notification === null || notification === "1");
    const debitCard = localStorage.getItem("TIPMATE_DEBIT_CARD");
    setShowDebitCard(!debitCard);
  }, []);

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {showNotification && (
        <Box
          sx={{
            border: "1px solid #007fff",
            background: "#fff",
            p: 2,
            borderRadius: 4,
            mb: 2,
          }}
        >
          <Typography variant="body1">
            New Account: Your tips are available for withdrawal after 2 business
            days. After 60 days, you may be eligible for instant access. Thanks
            for helping us make Tipmate safe for everyone! Questions? Feel free
            to contact us{" "}
            <Link
              href="mailto:support@tipmate.co"
              sx={{
                fontSize: "inherit",
                color: "inherit",
                textDecorationColor: "inherit",
              }}
            >
              support@tipmate.co
            </Link>
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button
              variant="outlined"
              size="small"
              onClick={handleNotificationClose}
            >
              Dismiss
            </Button>
          </Box>
        </Box>
      )}
      <MoreSteps />
      <Grid container mt={0} spacing={{ md: 4, xs: 0 }}>
        <Grid item md={6} xs={12}>
          <YourTipsCard data={balance} loading={loading} />
          <RewardsCard />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          sx={{ paddingTop: { xs: "32px !important" } }}
        >
          <Grid container spacing={2}>
            {cardList.map((card, index) => (
              <Grid key={index} item md={6} xs={6}>
                <Paper
                  sx={{
                    px: 1,
                    py: 3,
                    borderRadius: "16px",
                    height: "148px",
                    color: "#64748B",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(card.url);
                  }}
                >
                  {card.url === "#" && (
                    <StyledChip
                      label={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography fontSize={12} lineHeight="16px">
                            Coming soon
                          </Typography>
                        </Box>
                      }
                      sx={{
                        backgroundColor: "#F43F5E",
                        color: "#fff",
                        position: "absolute",
                        top: "16px",
                        right: "16px",
                      }}
                    />
                  )}
                  <card.icon sx={{ width: "24px", height: "24px", mb: 1 }} />
                  <Typography sx={{ fontSize: 18 }}>{card.title}</Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <RecentTips />
      {showDebitCard && (
        <Paper
          sx={{
            display: "flex",
            borderRadius: "16px",
            overflow: "hidden",
            mt: 1,
          }}
        >
          <Box
            sx={{ width: "calc(100% - 142px)", height: "160px", padding: 2 }}
          >
            <Typography sx={{ fontWeight: 600, mb: 0.5 }}>
              TipMate Debit Card
            </Typography>
            {tipmateCard !== "interested" ? (
              <>
                <Typography variant="body2" color="secondary" sx={{ mb: 0.5 }}>
                  Instant access to tips.
                </Typography>
                <Typography variant="body2" color="secondary" sx={{ mb: 0.5 }}>
                  Accepted everywhere
                </Typography>
              </>
            ) : (
              <Typography
                variant="body2"
                color="secondary"
                sx={{ mt: { md: 4, xs: 3 }, textAlign: "center" }}
              >
                Great! You’ll be the first to know when this feature is
                available.
              </Typography>
            )}
            {tipmateCard === "" && (
              <Box mt={2}>
                <Button
                  variant="contained"
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    padding: "10px 16px",
                    borderRadius: "12px",
                  }}
                  onClick={handleClkInterested}
                >
                  I'm interested
                </Button>
                <Button
                  variant="text"
                  sx={{
                    padding: "10px 8px",
                    fontSize: 14,
                    fontWeight: 600,
                    ml: 2,
                    borderRadius: "12px",
                  }}
                  onClick={handleClkDismiss}
                >
                  Dismiss
                </Button>
              </Box>
            )}
          </Box>
          <img src={debitCard} alt="debit card" />
        </Paper>
      )}
    </>
  );
}

export default Dashboard;
