import { ReactNode, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { isAccessTokenExpired } from "../utils";
import { useDispatch } from "react-redux";
import { logout, updateUser } from "../store/reducers/account";
import { AuthService } from "../api/auth/auth.service";

interface ProtectedRouteProps {
  children: ReactNode;
  redirectPath?: string;
}

const ProtectedRoute = ({
  children,
  redirectPath = "/signin",
}: ProtectedRouteProps) => {
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("authorization_token");
  if (!accessToken || isAccessTokenExpired(accessToken)) {
    dispatch(logout({}));
    return <Navigate to={redirectPath} replace />;
  }
  useEffect(() => {
    const fetchUserInfo = async () => {
      const data = await AuthService.getUser();
      dispatch(updateUser(data));
    };
    fetchUserInfo();
  }, []);
  return children;
};
export default ProtectedRoute;
