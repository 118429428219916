import { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Menu,
  MenuItem,
  Snackbar,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ShareIcon from "@mui/icons-material/Share";
// import googleWallet from "../assets/images/google-wallet.svg";
import BadgeViewer from "../components/BadgeViewer";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  XIcon,
} from "react-share";
import LinkIcon from "@mui/icons-material/Link";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";

function YourBadge() {
  const user = useSelector(({ account }) => account.user);
  const shareUrl = `${import.meta.env.VITE_BASE_URL}/sendtip/${encodeURI(
    user?.nickname
  )}-${user?.stripeAccountId.slice(-4)}`;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openAlert, setOpenAlert] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (!user) return;
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openAlert}
        autoHideDuration={6000}
        onClose={() => setOpenAlert(false)}
      >
        <Alert
          onClose={() => setOpenAlert(false)}
          variant="filled"
          severity="info"
          sx={{ width: "100%" }}
        >
          Copied to Clipboard
        </Alert>
      </Snackbar>
      <Button
        sx={{ fontSize: 14, fontWeight: 600 }}
        startIcon={<ArrowBackIcon sx={{ fontSize: "16px" }} />}
        href="/"
      >
        Back to Dashboard
      </Button>
      {!user?.stripeVerified ? (
        <Typography>Please complete the onboarding process first!</Typography>
      ) : (
        <>
          <Box
            sx={{
              marginTop: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">Your Badge</Typography>
            <Button
              variant="outlined"
              sx={{
                p: 1,
                borderRadius: "32px",
                minWidth: "auto",
                borderColor: "rgba(0, 0, 0, 0.10)",
                background: "#fff",
                "&:hover": {
                  borderColor: "rgba(0, 0, 0, 0.10)",
                },
              }}
              onClick={handleClick}
            >
              <ShareIcon sx={{ fontSize: "20px" }} />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  setOpenAlert(true);
                  handleClose();
                }}
                sx={{ padding: "4px !important" }}
              >
                <CopyToClipboard text={shareUrl}>
                  <LinkIcon sx={{ width: 32, height: 32 }} />
                </CopyToClipboard>
              </MenuItem>
              <MenuItem
                onClick={handleClose}
                sx={{ padding: "4px !important" }}
              >
                <FacebookShareButton url={shareUrl}>
                  <FacebookIcon size={32} round display="block" />
                </FacebookShareButton>
              </MenuItem>
              <MenuItem
                onClick={handleClose}
                sx={{ padding: "4px !important" }}
              >
                <TwitterShareButton url={shareUrl}>
                  <XIcon size={32} round display="block" />
                </TwitterShareButton>
              </MenuItem>
              <MenuItem
                onClick={handleClose}
                sx={{ padding: "4px !important" }}
              >
                <EmailShareButton
                  url={shareUrl}
                  subject="Checkout URL"
                  body="body"
                >
                  <EmailIcon size={32} round display="block" />
                </EmailShareButton>
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ width: "320px", mx: "auto", mt: 5 }}>
            <BadgeViewer />
          </Box>
          {/* <Button
            variant="contained"
            sx={{
              borderRadius: "25px",
              padding: "12px 40px",
              fontSize: "14px",
              lineHeight: "20px",
            }}
            fullWidth
            startIcon={
              <img src={googleWallet} height={20} alt="google wallet" />
            }
          >
            Add to Google Wallet
          </Button> */}
        </>
      )}
    </>
  );
}

export default YourBadge;
