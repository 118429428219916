import { Outlet } from "react-router";
import logo from "../assets/images/logo.svg";
import { ContentWrapper, MainWrapper } from "../components/styled";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import ProtectedRoute from "./ProtectedRoute";

const SecondaryLayout = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <ProtectedRoute>
      <Box sx={{ minHeight: "100vh", backgroundColor: "#F1F5F9" }}>
        {matches && (
          <Box>
            <MainWrapper>
              <Box sx={{ p: 2 }}>
                <img
                  src={logo}
                  height={32}
                  alt="logo"
                  style={{ display: "block" }}
                />
              </Box>
            </MainWrapper>
          </Box>
        )}
        <ContentWrapper sx={{ padding: { md: "40px", xs: "24px" } }}>
          <Outlet />
        </ContentWrapper>
      </Box>
    </ProtectedRoute>
  );
};

export default SecondaryLayout;
