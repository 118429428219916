import { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import QRCode from "react-qr-code";
import wifiImg from "../assets/images/tipmate-wifi.png";
import CloseIcon from "@mui/icons-material/Close";
import { UserData } from "../types/auth";
import { AppState } from "../store/reducers";

interface BadgeViewerProps {
  isStatic?: boolean;
}

function BadgeViewer({ isStatic = false }: BadgeViewerProps) {
  const user = useSelector<AppState, UserData | null>(
    ({ account }) => account.user
  );
  const [expanded, setExpanded] = useState(false);
  return (
    <Box
      sx={{
        border: "1px solid #9E519F",
        borderRadius: "16px",
        background: "#FFF",
        width: "296px",
        marginX: "auto",
        my: 3,
        boxShadow: "0px 8px 20px 0px rgba(0, 0, 0, 0.10)",
      }}
    >
      <Box sx={{ padding: "16px 24px" }}>
        {expanded ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 700,
                  fontFamily: "Darker Grotesque",
                  lineHeight: "24px",
                  color: "#9E519F",
                }}
              >
                TAP, TIP, HOORAY
              </Typography>
              <IconButton
                sx={{ width: "24px", height: "24px" }}
                onClick={() => setExpanded(false)}
              >
                <CloseIcon sx={{ width: "12px", height: "12px" }} />
              </IconButton>
            </Box>
            <Box
              sx={{
                padding: "10px",
                border: "2px solid #9E519F",
                borderRadius: "16px",
                background: "#fff",
                width: "fit-content",
              }}
            >
              <QRCode
                size={224}
                value={
                  isStatic
                    ? "https://tipmate.co"
                    : `${import.meta.env.VITE_BASE_URL}/sendtip/${encodeURI(
                        user?.nickname || ""
                      )}-${(user?.stripeAccountId || "").slice(-4)}`
                }
                style={{ display: "block" }}
              />
            </Box>
            <Typography
              sx={{
                fontSize: 24,
                fontWeight: 700,
                lineHeight: "32px",
                fontFamily: "Darker Grotesque",
                mt: 2,
                textAlign: "center",
              }}
            >
              {user?.nickname}
            </Typography>
          </>
        ) : (
          <>
            <Box sx={{ position: "relative", width: "100%" }}>
              <img
                src={user?.photoURL}
                width={200}
                height={250}
                alt="avatar"
                style={{
                  borderRadius: "24px",
                  border: "1px solid #9E519F",
                  display: "block",
                  objectFit: "cover",
                }}
              />
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 700,
                  fontFamily: "Darker Grotesque",
                  lineHeight: "24px",
                  textOrientation: "sideways",
                  writingMode: "vertical-lr",
                  color: "#9E519F",
                  position: "absolute",
                  right: 0,
                  top: 0,
                }}
              >
                TAP, TIP, HOORAY
              </Typography>
              <Box
                sx={{
                  padding: "10px",
                  border: "2px solid #9E519F",
                  borderRadius: "16px",
                  background: "#fff",
                  width: "fit-content",
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  cursor: "pointer",
                }}
                onClick={() => setExpanded(true)}
              >
                <QRCode
                  size={64}
                  value={
                    isStatic
                      ? "https://tipmate.co"
                      : `${import.meta.env.VITE_BASE_URL}/sendtip/${encodeURI(
                          user?.nickname || ""
                        )}-${(user?.stripeAccountId || "").slice(-4)}`
                  }
                  style={{ display: "block" }}
                />
              </Box>
            </Box>
            <Typography
              sx={{
                fontSize: 32,
                fontWeight: 700,
                lineHeight: "32px",
                fontFamily: "Darker Grotesque",
                mt: 2,
              }}
            >
              {user?.nickname}
            </Typography>
          </>
        )}
      </Box>
      <Box
        sx={{
          borderRadius: "14px",
          background: "#9E519F",
          padding: "16px 24px",
        }}
      >
        <img
          src={wifiImg}
          width="100%"
          style={{ display: "block" }}
          alt="wifi"
        />
      </Box>
    </Box>
  );
}

export default BadgeViewer;
