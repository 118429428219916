import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import cookie from "react-cookies";
import { BootstrapDialog } from "./styled";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { ProfileService } from "../api/profile/profile.service";
import { StripeService } from "../api/stripe/stripe.service";
import { BankAccountData, CardData, UserData } from "../types/auth";
import { AppState } from "../store/reducers";

function MoreSteps() {
  const user = useSelector<AppState, UserData | null>(
    ({ account }) => account.user
  );
  console.log("Checking steps for user", user);
  const navigate = useNavigate();
  const [verifyUrl, setVerifyUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [checking, setChecking] = useState(true);
  const [promoCodeDlg, setPromoCodeDlg] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [notification, setNotification] = useState("");
  const [activationUrl, setActivationUrl] = useState("");
  const [bankAccounts, setBankAccounts] = useState<BankAccountData[]>([]);
  const [debitCards, setDebitCards] = useState<CardData[]>([]);
  const steps = [
    {
      title: "Account setup",
      passed: !!user?.stripeAccountId,
      action: "/onboarding/personal-information",
      disabled: false,
    },
    {
      title: "Pay $5 activation fee",
      passed: user?.activated,
      action: activationUrl,
      disabled: !user?.stripeAccountId,
    },
    {
      title: "Add bank accounts / debit cards",
      passed: user?.activated && (bankAccounts.length || debitCards.length),
      action: "/settings#manage_accounts",
      disabled: !user?.activated,
    },
    {
      title: "Identity verification",
      passed: user?.stripeVerified,
      action: verifyUrl,
      disabled: !bankAccounts.length && !debitCards.length,
    },
  ];
  const handleClose = () => {
    setPromoCodeDlg(false);
  };
  const handleSubmit = async () => {
    const data = await ProfileService.checkPromoCode(promoCode.trim());
    console.log(data);
    if (data.isValid) {
      navigate("/");
    } else {
      setNotification("The provided promo code is not working.");
    }
  };
  const handlePay = () => {
    handleClose();
    navigate(steps[1].action);
  };
  const verifyAccount = async () => {
    const url = cookie.load("TIPMATE_VERIFY_ACCOUNT_URL");
    if (url) {
      setVerifyUrl(url);
    } else {
      const data = await StripeService.verify();
      setVerifyUrl(data.url);
      cookie.save("TIPMATE_VERIFY_ACCOUNT_URL", data.url, { maxAge: 120 });
    }
  };
  const activateAccount = async () => {
    const url = cookie.load("TIPMATE_ACTIVATE_ACCOUNT_URL");
    if (url) {
      setActivationUrl(url);
    } else {
      const data = await ProfileService.activateAccount();
      setActivationUrl(data.url);
      cookie.save("TIPMATE_ACTIVATE_ACCOUNT_URL", data.url, { maxAge: 120 });
    }
  };
  useEffect(() => {
    const checkAccount = async () => {
      setChecking(true);
      if (user && !user.stripeVerified) {
        await verifyAccount();
      }
      if (user && !user.activated) {
        await activateAccount();
      }
      if (user && !user.selfEmployed) {
        steps.push({
          title: "Employer & manager info",
          passed:
            JSON.stringify(user?.employer) !== "{}" &&
            JSON.stringify(user?.manager) !== "{}",
          action: "/onboarding/employer-information",
          disabled: !user?.activated,
        });
      }
      setChecking(false);
    };
    if (user) {
      checkAccount();
    }
  }, [user]);
  const fetchAccounts = async () => {
    try {
      setLoading(true);
      const resb = await ProfileService.getBankAccount();
      setBankAccounts(resb.data);
      const resc = await ProfileService.getCards();
      setDebitCards(resc.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAccounts();
  }, []);

  return (
    <>
    {
      // The value of the promoCode variable comes from user input and is directly written to the DOM.
      // An attacker can exploit the vulnerability by injecting malicious code as the promoCode value.
      // Code injections can hijack the user session to perform unintended actions.
    
      (console.log(steps))
    }
      {!loading &&
        !checking &&
        steps.filter((x) => x.passed === false).length > 0 && (
          <Box
            sx={{
              borderRadius: "16px",
              border: "1px solid #FF5050",
              overflow: "hidden",
              mb: 2,
            }}
          >
            <Box sx={{ padding: "24px 16px", background: "#FF5050" }}>
              <Typography variant="h5" sx={{ color: "#ffffff" }}>
                Let’s get you paid!
              </Typography>
            </Box>
            <Box sx={{ p: 2, background: "#ffffff" }}>
              <Typography variant="body2" mb={1}>
                A few steps left to start collecting tips and earning rewards
                with TipMate!
              </Typography>
              <List disablePadding>
                {steps.map((step) => (
                  <ListItemButton
                    key={step.title}
                    sx={
                      step.passed || step.disabled
                        ? { pointerEvents: "none", px: 1, py: 0 }
                        : { px: 1, py: 0 }
                    }
                    href={
                      step.title !== "Pay $5 activation fee" &&
                      !step.passed &&
                      !step.disabled
                        ? step.action
                        : ""
                    }
                    onClick={() =>
                      step.title === "Pay $5 activation fee"
                        ? setPromoCodeDlg(true)
                        : null
                    }
                  >
                    <ListItemIcon sx={{ minWidth: "32px", mt: 0.5 }}>
                      {step.passed ? (
                        <CheckCircleIcon
                          color="success"
                          sx={{ width: "24px", height: "24px" }}
                        />
                      ) : (
                        <RadioButtonUncheckedIcon
                          color="secondary"
                          sx={{ width: "24px", height: "24px" }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        variant="body2"
                        component="span"
                        color={step.passed ? "success.main" : ""}
                      >
                        {step.title}
                      </Typography>
                    </ListItemText>
                    {!step.passed && !step.disabled && (
                      <ArrowForwardIcon
                        sx={{ color: "#FF5050", width: "12px", height: "12px" }}
                      />
                    )}
                  </ListItemButton>
                ))}
              </List>
            </Box>
          </Box>
        )}
      <BootstrapDialog
        onClose={handleClose}
        open={promoCodeDlg}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Do you have a promo code?</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            zIndex: 1001,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <TextField
            autoFocus
            variant="outlined"
            placeholder="Enter your promo code"
            fullWidth
            size="small"
            inputProps={{
              style: {
                color: "#334155",
                border: "0.5px solid rgba(0, 0, 0, 0.10)",
                borderRadius: "4px",
                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
              },
            }}
            onChange={(e) => {
              setNotification("");
              setPromoCode(e.target.value);
            }}
          />
          {notification !== "" && (
            <Box
              sx={{
                mt: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              <ErrorOutlineIcon fontSize="inherit" color="error" />
              <Typography color="error" sx={{ ml: 0.5 }}>
                {notification}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            disabled={promoCode === ""}
            onClick={handleSubmit}
          >
            Yes, I will submit!
          </Button>
          <Button variant="outlined" size="small" onClick={handlePay}>
            No, I will pay $5!
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export default MoreSteps;
