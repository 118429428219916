import { Button, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function Rewards() {
  return (
    <>
      <Button
        sx={{ fontSize: 14, fontWeight: 600 }}
        startIcon={<ArrowBackIcon sx={{ fontSize: "16px" }} />}
        href="/"
      >
        Back to Dashboard
      </Button>
      <Typography variant="h5" mt={1}>
        TipMate Rewards
      </Typography>
      <Typography mt={4}>Awesome rewards coming soon...</Typography>
      <Typography mt={2}>
        While we work on this, you'll still be able earning one point for every
        dollar you earn!
      </Typography>
      <Typography>
        Check back here to see your rewards balance.
      </Typography>
    </>
  );
}

export default Rewards;
