import { Outlet } from "react-router";
import logo from "../assets/images/logo.svg";
import { ContentWrapper, MainWrapper } from "../components/styled";
import { Box } from "@mui/material";

const PublicLayout = () => {
  return (
    <Box sx={{ minHeight: "100vh", backgroundColor: "#F1F5F9" }}>
      <Box>
        <MainWrapper>
          <Box sx={{ p: 2 }}>
            <img
              src={logo}
              height={32}
              alt="logo"
              style={{ display: "block" }}
            />
          </Box>
        </MainWrapper>
      </Box>
      <ContentWrapper sx={{ mt: 4, padding: { md: "40px", xs: "0 16px" } }}>
        <Outlet />
      </ContentWrapper>
    </Box>
  );
};

export default PublicLayout;
