import { Box, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { StyledListItemButton } from "./styled";
import ImageIcon from "@mui/icons-material/Image";
import { convertTimestampToDateTime } from "../utils";
import { useEffect, useState } from "react";
import { TransactionData } from "../types/transaction";

interface TransactionHistoryItemProps {
  data: TransactionData;
}

export const TransactionHistoryItem = ({
  data,
}: TransactionHistoryItemProps) => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState(0);
  useEffect(() => {
    if (data && data.type === "payout") {
      // let metadata = data.metadata;
      setAmount(data?.amount || Math.abs(data.net));
    }
  }, [data]);
  return (
    <StyledListItemButton
      onClick={() => {
        navigate(`/transaction/${data.id}`);
      }}
    >
      <ListItemIcon sx={{ minWidth: "40px" }}>
        <ImageIcon sx={{ width: "32px", height: "32px" }} />
      </ListItemIcon>
      <ListItemText
        sx={{ m: 0 }}
        primary={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
                color: data.type === "payment" ? "#0F172A" : "#DC2626",
              }}
            >
              {data.type === "payment" ? "Tip" : "Payout"}
            </Typography>
          </Box>
        }
        secondary={
          <Typography sx={{ fontSize: 12, color: "#64748B" }}>
            {convertTimestampToDateTime(data.created)}
          </Typography>
        }
      />
      <Typography
        sx={{
          fontWeight: 600,
          color: data.type === "payment" ? "#16A34A" : "#DC2626",
        }}
      >
        {data.type === "payment" ? "+" : "-"} $
        {(
          (data.type === "payment" ? Math.abs(data.net) : amount) / 100
        ).toFixed(2)}
      </Typography>
    </StyledListItemButton>
  );
};
