import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import { StyledTextField } from "../styled";
import { IEmployerInfo } from "../../types/auth";

interface EmployerInformationProps {
  data: IEmployerInfo;
  setData: (data: IEmployerInfo) => void;
}

function EmployerInformation({ data, setData }: EmployerInformationProps) {
  const handleChange = (
    type: "employer" | "manager",
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setData({
      ...data,
      [type]: { ...data[type], [e.target.name]: e.target.value },
    });
  };
  return (
    <>
      <Box>
        <Typography variant="subtitle1">Employer Information</Typography>
        <FormControlLabel
          control={
            <Switch
              checked={data.selfEmployed}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setData({
                  ...data,
                  selfEmployed: e.target.checked,
                })
              }
            />
          }
          label="I am self-employed"
          sx={{ mt: 2 }}
        />
        {!data.selfEmployed && (
          <>
            <Box mt={2}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#64748B",
                  mb: 0.5,
                }}
              >
                Employer Name
              </Typography>
              <StyledTextField
                name="name"
                placeholder="Enter name of your employer"
                sx={{ width: "100%" }}
                value={data.employer.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange("employer", e)
                }
              />
            </Box>
            <Box mt={2}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#64748B",
                  mb: 0.5,
                }}
              >
                Website
              </Typography>
              <StyledTextField
                name="website"
                placeholder="Enter website..."
                sx={{ width: "100%" }}
                value={data.employer.website}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange("employer", e)
                }
              />
            </Box>
            <Box mt={2}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#64748B",
                  mb: 0.5,
                }}
              >
                Address
              </Typography>
              <StyledTextField
                name="address"
                placeholder="Enter employer address"
                sx={{ width: "100%" }}
                value={data.employer.address}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange("employer", e)
                }
              />
            </Box>
          </>
        )}
      </Box>
      {!data.selfEmployed && (
        <Box mt={4}>
          <Typography variant="subtitle1">Manager Information</Typography>
          <Box mt={2}>
            <Typography
              sx={{ fontSize: 14, fontWeight: 600, color: "#64748B", mb: 0.5 }}
            >
              Manager Name
            </Typography>
            <StyledTextField
              name="name"
              placeholder="Enter name of your manager"
              sx={{ width: "100%" }}
              value={data.manager.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange("manager", e)
              }
            />
          </Box>
          <Box mt={2}>
            <Typography
              sx={{ fontSize: 14, fontWeight: 600, color: "#64748B", mb: 0.5 }}
            >
              Phone Number
            </Typography>
            <StyledTextField
              name="phoneNumber"
              placeholder="Enter number..."
              sx={{ width: "100%" }}
              value={data.manager.phoneNumber}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange("manager", e)
              }
            />
          </Box>
          <Box mt={2}>
            <Typography
              sx={{ fontSize: 14, fontWeight: 600, color: "#64748B", mb: 0.5 }}
            >
              Email
            </Typography>
            <StyledTextField
              name="email"
              type="email"
              placeholder="Enter manager email..."
              sx={{ width: "100%" }}
              value={data.manager.email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange("manager", e)
              }
            />
          </Box>
        </Box>
      )}
    </>
  );
}

export default EmployerInformation;
