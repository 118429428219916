import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Chip,
  Dialog,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import logo from "../assets/images/logo.svg";
import { MainWrapper, StyledButton } from "./styled";
import { NAVS } from "../constants/navs";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";
import { Logout } from "@mui/icons-material";
import { UserData } from "../types/auth";
import { AppState } from "../store/reducers";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function Header() {
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const { pathname } = useLocation();
  const user = useSelector<AppState, UserData | null>(
    ({ account }) => account.user
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDlgClose = () => {
    setOpenDialog(false);
  };
  const handleSignOut = () => {
    handleClose();
    localStorage.removeItem("authorization_token");
    navigate("/signin");
  };
  const renderDesktop = () => (
    <MainWrapper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
          <img src={logo} height={32} alt="logo" />
          <List sx={{ display: "flex", ml: 3, p: 0 }}>
            {NAVS.map(
              (item) =>
                item.label !== "Settings" && (
                  <ListItem key={item.label} sx={{ mr: 1 }} disablePadding>
                    <ListItemButton href={item.url} sx={{ p: 1 }}>
                      <ListItemIcon sx={{ minWidth: "30px" }}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        sx={{ m: 0 }}
                        primary={
                          <Typography variant="body2" noWrap>
                            {item.label}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                )
            )}
          </List>
        </Box>
        <Box>
          <IconButton size="small" sx={{ mr: 1 }} href="/settings">
            <SettingsOutlinedIcon sx={{ fontSize: "18px" }} />
          </IconButton>
          <Chip
            sx={{ borderRadius: "8px", cursor: "pointer" }}
            avatar={
              <Avatar
                alt="avatar"
                src={user?.photoURL}
                sx={{ border: "1px solid white" }}
              />
            }
            label={
              <Typography variant="body2" component="span">
                {user?.nickname}
              </Typography>
            }
            onClick={handleClick}
          />
        </Box>
      </Box>
    </MainWrapper>
  );

  const renderMobile = () => (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 2,
        }}
      >
        <Box sx={{ textAlign: "center", flex: 1 }}>
          <img
            src={logo}
            height={32}
            alt="logo"
            style={{ display: "block", margin: "auto" }}
          />
        </Box>
        <IconButton
          size="small"
          aria-label="menu"
          onClick={() => setOpenDialog(true)}
        >
          <MenuIcon sx={{ width: 24, height: 24 }} />
        </IconButton>
      </Box>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleDlgClose}
        TransitionComponent={Transition}
        sx={{ "& .MuiPaper-root": { background: "#f1f5f9" } }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <img src={logo} height={32} alt="logo" />
              <IconButton
                size="small"
                color="inherit"
                onClick={handleDlgClose}
                aria-label="close"
                sx={{ p: 0 }}
              >
                <CloseIcon sx={{ width: 32, height: 32 }} />
              </IconButton>
            </Box>
            <List sx={{ mt: 3 }}>
              {NAVS.map((item) => (
                <ListItem
                  key={item.label}
                  sx={{ mr: 1, display: "block" }}
                  disablePadding
                >
                  <ListItemButton href={item.url} sx={{ p: 1 }}>
                    <ListItemIcon sx={{ minWidth: "30px" }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      sx={{ m: 0 }}
                      primary={
                        <Typography variant="body2" noWrap>
                          {item.label}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                  {item.children && (
                    <List sx={{ p: "0 24px" }}>
                      {item.children.map((subItem) => (
                        <ListItem
                          key={subItem.label}
                          sx={{ mr: 1 }}
                          disablePadding
                        >
                          <ListItemButton href={subItem.url} sx={{ p: 1 }}>
                            <ListItemIcon sx={{ minWidth: "30px" }}>
                              {subItem.icon}
                            </ListItemIcon>
                            <ListItemText
                              sx={{ m: 0 }}
                              primary={
                                <Typography variant="body2" noWrap>
                                  {subItem.label}
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  )}
                </ListItem>
              ))}
            </List>
          </Box>
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 1,
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Avatar
                  alt="avatar"
                  src={user?.photoURL}
                  sx={{ border: "1px solid #E2E8F0", mr: 1 }}
                />
                <Box>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: 600, lineHeight: "20px" }}
                  >
                    {user?.nickname}
                  </Typography>
                  <Typography
                    sx={{ fontSize: 12, lineHeight: "16px", color: "#94A3B8" }}
                  >
                    {user?.email}
                  </Typography>
                </Box>
              </Box>
              <StyledButton
                variant="contained"
                sx={{ p: "10px 16px", borderRadius: "12px" }}
                onClick={handleSignOut}
              >
                Sign Out
              </StyledButton>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );

  return (
    <>
      {matches ? renderDesktop() : pathname === "/" && renderMobile()}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="inherit" />
          </ListItemIcon>
          <Typography variant="body1">Settings</Typography>
        </MenuItem> */}
        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <Logout fontSize="inherit" />
          </ListItemIcon>
          <Typography variant="body2">Sign Out</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export default Header;
