import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import OnboardingTemplate from "./OnboardingTemplate";
import { StyledTextField } from "../../components/styled";

import { ProfileService } from "../../api/profile/profile.service";
import { Employer } from "../../types/auth";

function EmployerInformation() {
  const navigate = useNavigate();
  const user = useSelector(({ account }) => account);
  const [employer, setEmployer] = useState({
    name: "",
    website: "",
    address: "",
  });
  const [selfEmployed, setSelfEmployed] = useState(false);
  const [validation, setValidation] = useState(false);
  const handleContinue = async () => {
    setValidation(true);
    if (
      !selfEmployed &&
      (employer.name === "" ||
        employer.website === "" ||
        employer.address === "")
    ) {
      return;
    }
    try {
      const res = await ProfileService.saveInformation({
        selfEmployed,
        employer: selfEmployed ? null : employer,
      });
      console.log(res);
      navigate(selfEmployed
        ? "/onboarding/onboarding-confirmation"
        : "/onboarding/manager-information");
    } catch (err) {
      console.log(err);
    }
  };
  const handleSkip = () => {
    navigate("/onboarding/manager-information");
  };
  const handleBack = () => {
    navigate("/onboarding/photo");
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmployer((prev: Employer) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (user) {
      setSelfEmployed(user.selfEmployed || false);
      setEmployer({
        name: user.employer?.name || "",
        website: user.employer?.website || "",
        address: user.employer?.address || "",
      });
    }
  }, [user]);

  return (
    <OnboardingTemplate
      totalSteps={selfEmployed ? 5 : 6}
      currentStep={5}
      title="Your Employer"
      description="Make tip reporting easy, by sharing your employer and manager information."
      banner={
        <Typography
          sx={{
            fontFamily: "Darker Grotesque",
            lineHeight: "20px",
            color: "#161215",
            textAlign: "center",
          }}
        >
          A world with more tips is around the corner!
        </Typography>
      }
      skippable={true}
      handleContinue={handleContinue}
      handleSkip={handleSkip}
      handleBack={handleBack}
    >
      <FormControlLabel
        control={
          <Switch
            checked={selfEmployed}
            onChange={(e) => setSelfEmployed(e.target.checked)}
          />
        }
        label="I am self-employed."
        sx={{ mb: 3 }}
      />
      {!selfEmployed && (
        <>
          <Box mb={3}>
            <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 0.5 }}>
              Employer Name
            </Typography>
            <StyledTextField
              name="name"
              placeholder="Enter employer name"
              sx={{ width: "100%" }}
              value={employer.name}
              error={validation && !employer.name}
              onChange={handleChange}
            />
          </Box>
          <Box mb={3}>
            <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 0.5 }}>
              Website
            </Typography>
            <StyledTextField
              name="website"
              placeholder="Enter employer website"
              sx={{ width: "100%" }}
              value={employer.website}
              error={validation && !employer.website}
              onChange={handleChange}
            />
          </Box>
          <Box mb={3}>
            <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 0.5 }}>
              Address
            </Typography>
            <StyledTextField
              name="address"
              placeholder="Enter employer address"
              sx={{ width: "100%" }}
              value={employer.address}
              error={validation && !employer.address}
              onChange={handleChange}
            />
          </Box>
        </>
      )}
    </OnboardingTemplate>
  );
}

export default EmployerInformation;
