import { Outlet } from "react-router";
import { ContentWrapper } from "../components/styled";

const AuthLayout = () => {
  return (
    <ContentWrapper sx={{ mt: { md: 12, xs: 6 }, mb: { md: 5, sx: 2 } }}>
      <Outlet />
    </ContentWrapper>
  );
};

export default AuthLayout;
