import { Input, InputAdornment, Typography, styled } from "@mui/material";
import { useState, useRef, useEffect } from "react";

const StyledInput = styled(Input)({
  color: "#0F172A",
  fontSize: 88,
  fontWeight: 400,
  fontFamily: "Darker Grotesque",
  input: {
    height: "104px",
    "&:hover": {
      color: "#A000BF",
    },
    "&:focus": {
      color: "#A000BF",
    },
  },
  "*:has(+ input:focus)": {
    "& .MuiTypography-root": {
      color: "#A000BF",
    },
  },
  "&:before": {
    borderBottomColor: "#0F172A",
  },
  "&:hover": {
    "& .MuiInputAdornment-root .MuiTypography-root": {
      color: "#A000BF",
    },
  },
  "&:hover:before": {
    borderBottomColor: "#A000BF !important",
  },
});

interface CustomInputProps {
  value: string;
  setValue: Function;
}

export const CustomInput = ({ value, setValue }: CustomInputProps) => {
  const [width, setWidth] = useState(72);
  const span = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    setTimeout(() => {
      if (span.current) {
        setWidth(span.current.offsetWidth + 60);
      }
    }, 50);
  }, [value]);

  return (
    <>
      <Typography
        ref={span}
        variant="h2"
        component="span"
        sx={{ position: "absolute", opacity: 0, letterSpacing: 1 }}
      >
        {value}
      </Typography>
      <StyledInput
        type="number"
        style={{ width }}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => setValue(parseFloat(value).toFixed(2))}
        value={value}
        autoFocus
        inputProps={{ min: 0.5, max: 9999 }}
        startAdornment={
          <InputAdornment position="start">
            <Typography
              sx={{
                fontSize: 88,
                color: "#0F172A",
                fontFamily: "Darker Grotesque",
              }}
            >
              $
            </Typography>
          </InputAdornment>
        }
      />
    </>
  );
};
