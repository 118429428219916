import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import bgImage from "../../assets/images/bg-image2.svg";
import bgImageMobile from "../../assets/images/bg-image-m.svg";
import { Stepper } from "../../components/Stepper";
import { MouseEventHandler, ReactNode, useState } from "react";
import { StyledButton } from "../../components/styled";

interface OnboardingTemplateProps {
  banner: ReactNode;
  children: ReactNode;
  totalSteps?: number;
  currentStep: number;
  title: string;
  description: string;
  skippable?: boolean;
  disableContinue?: boolean;
  handleContinue: MouseEventHandler;
  handleSkip?: MouseEventHandler;
  handleBack?: MouseEventHandler;
}

function OnboardingTemplate({
  children,
  banner,
  totalSteps = 6,
  currentStep,
  title,
  description,
  handleContinue,
  disableContinue = false,
  skippable = false,
  handleSkip,
  handleBack,
}: OnboardingTemplateProps) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [processing, setProcessing] = useState(false);
  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      setProcessing(true);
      await handleContinue(e);
      setProcessing(false);
    } catch (e) {
      console.log(e);
      setProcessing(false);
    }
  };
  return (
    <>
      <Box
        sx={{
          padding: { md: "24px 32px", xs: "24px 16px" },
          borderRadius: "16px",
          position: "relative",
          overflow: "hidden",
          width: "100%",
          boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
        }}
      >
        <img
          src={matches ? bgImage : bgImageMobile}
          width="100%"
          height="100%"
          style={{ position: "absolute", inset: 0 }}
          alt="background"
        />
        {banner}
      </Box>
      <Box sx={{ mt: { md: 4, xs: 3 } }}>
        {currentStep > 1 && (
          <Button
            variant="outlined"
            sx={{
              padding: "10px 16px",
              borderRadius: "12px",
              fontSize: 14,
              lineHeight: "20px",
              border: "1px solid #161215",
              mb: 4,
            }}
            startIcon={
              <ArrowBackIcon color="primary" sx={{ width: 16, height: 16 }} />
            }
            onClick={handleBack}
          >
            Back
          </Button>
        )}
        <Stepper total={totalSteps} current={currentStep} />
        <Typography variant="h6" mt={1}>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }} color="secondary">
          {description}
        </Typography>
        <Box my={3}>{children}</Box>
        <Box sx={{ display: "flex" }}>
          {skippable && (
            <StyledButton
              variant="text"
              sx={{ mr: 2, backgroundColor: "#fff !important" }}
              fullWidth
              onClick={handleSkip}
            >
              Skip for now
            </StyledButton>
          )}
          <StyledButton
            variant="contained"
            fullWidth
            onClick={handleClick}
            disabled={processing || disableContinue}
          >
            Continue
          </StyledButton>
        </Box>
      </Box>
    </>
  );
}

export default OnboardingTemplate;
