import { useState } from "react";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../store/reducers/account";
import { StyledButton, StyledTextField } from "../components/styled";
import Logo from "../assets/images/logo.svg";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Link,
  Snackbar,
  Typography,
} from "@mui/material";
import { setAuthorizationToken } from "../utils";
import { AuthService } from "../api/auth/auth.service";
// import { useNavigate } from "react-router-dom";

function SignIn() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const [credential, setCredential] = useState({
    email: { value: "", helperText: "" },
    password: { value: "", helperText: "" },
  });
  const [email, setEmail] = useState({
    value: "",
    helperText: "",
  });
  const [keepSignedIn, setKeepSignedIn] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDlg, setOpenDlg] = useState(false);

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleDlgClose = () => {
    setOpenDlg(false);
  };
  const openForgotPasswordDlg = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setOpenDlg(true);
  };
  const handleForgotPassword = async () => {
    let isValid = true;
    if (!emailPattern.test(email.value)) {
      setCredential((prev) => ({
        ...prev,
        helperText: "Email is not valid.",
      }));
      isValid = false;
    }
    if (!isValid) {
      return;
    }
    try {
      const data = await AuthService.forgotPassword(
        email.value.trim().toLowerCase()
      );
      console.log(data);
      setOpenDlg(false);
    } catch (err) {
      console.log(err);
      setOpenDlg(false);
    }
  };
  const handleSignIn = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    let isValid = true;
    if (credential.password.value === "") {
      setCredential((prev) => ({
        ...prev,
        password: {
          value: prev.password.value,
          helperText: "Input your password.",
        },
      }));
      isValid = false;
    }
    if (!emailPattern.test(credential.email.value)) {
      setCredential((prev) => ({
        ...prev,
        email: {
          value: prev.email.value,
          helperText: "Email is not valid.",
        },
      }));
      isValid = false;
    }
    if (!isValid) {
      return;
    }
    try {
      const data = await AuthService.login(
        credential.email.value.trim().toLowerCase(),
        credential.password.value
      );
      setAuthorizationToken(data.token);
      dispatch(loginSuccess({ keepSignedIn }));
      window.location.href = "/";
      // navigate("/");
    } catch (err) {
      console.log(err);
      setOpen(true);
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCredential((prev) => ({
      ...prev,
      [e.target.name]: { value: e.target.value, helperText: "" },
    }));
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          severity="error"
          sx={{ width: "100%" }}
        >
          The provided email and password are incorrect!
        </Alert>
      </Snackbar>
      <img src={Logo} width={200} alt="logo" style={{ display: "block" }} />
      <Typography variant="h5" mt={2}>
        Welcome back
      </Typography>
      <Typography mt={1}>
        Enter your account details below to sign in
      </Typography>
      <Box my={4}>
        <form>
          <Box mb={2}>
            <Typography variant="body2" fontWeight={600} mb={0.5}>
              Email
            </Typography>
            <StyledTextField
              id="email"
              name="email"
              placeholder="Email"
              variant="outlined"
              fullWidth
              error={!!credential.email.helperText}
              helperText={credential.email.helperText}
              onChange={handleInputChange}
            />
          </Box>
          <Box mb={2}>
            <Typography variant="body2" fontWeight={600} mb={0.5}>
              Password
            </Typography>
            <StyledTextField
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              variant="outlined"
              fullWidth
              error={!!credential.password.helperText}
              helperText={credential.password.helperText}
              onChange={handleInputChange}
            />
          </Box>
          <Box
            sx={{
              mb: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox onChange={(e) => setKeepSignedIn(e.target.checked)} />
              }
              label={
                <Typography variant="body2" color="secondary">
                  Keep me signed in
                </Typography>
              }
            />
            <Link
              href="#"
              sx={{ textDecoration: "none" }}
              onClick={openForgotPasswordDlg}
            >
              <Typography variant="body2" fontWeight={600}>
                Forgot password
              </Typography>
            </Link>
          </Box>
          <Box>
            <StyledButton
              type="submit"
              variant="contained"
              fullWidth
              onClick={handleSignIn}
            >
              Sign In
            </StyledButton>
          </Box>
        </form>
      </Box>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Typography variant="body2" mr={3}>
          Don’t have an account?
        </Typography>
        <Link
          href="/signup"
          sx={{ textDecoration: "none", fontSize: "14px", fontWeight: 600 }}
        >
          Sign Up
        </Link>
      </Box>

      <Dialog open={openDlg} onClose={handleDlgClose} maxWidth="md" fullWidth>
        <DialogTitle id="alert-dialog-title">
          Do you want to reset your password?
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" mb={0.5}>
            Please input your email address.
          </Typography>
          <StyledTextField
            placeholder="Email"
            variant="outlined"
            fullWidth
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail({ value: e.target.value, helperText: "" })
            }
            error={!!email.helperText}
            helperText={email.helperText}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleForgotPassword}>Reset Password</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SignIn;
