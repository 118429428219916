import { Box, Button, Link, Typography } from "@mui/material";
import ArrowForward from "@mui/icons-material/ArrowForward";
import bgImage from "../assets/images/bg-image.svg";
import { BalanceData } from "../types/auth";

interface YourTipsCardProps {
  data?: BalanceData;
  loading: boolean;
}

function YourTipsCard({
  data = { balanceAvailable: 0, balancePending: 0 },
  loading,
}: YourTipsCardProps) {
  return (
    <Link href="/transfer" sx={{ textDecoration: "none" }}>
      <Box
        sx={{
          p: 2,
          position: "relative",
          borderRadius: "16px",
          border: "1px solid rgba(0, 0, 0, 0.25)",
          overflow: "hidden",
          boxShadow:
            "0px 0px 16px 4px rgba(255, 255, 255, 0.25) inset, 0px 3px 2px 0px rgba(0, 0, 0, 0.13)",
        }}
      >
        <img
          src={bgImage}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            inset: 0,
            zIndex: -1,
          }}
          alt="background"
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="subtitle1">Your tips</Typography>
          <Button
            variant="text"
            endIcon={<ArrowForward sx={{ width: "16px", height: "16px" }} />}
          >
            Cash out
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Typography
            sx={{
              fontSize: 48,
              fontWeight: 700,
              lineHeight: "120%",
              letterSpacing: "0.96px",
              fontFamily: "Darker Grotesque",
              opacity: loading ? 0 : 1,
              mr: 1,
            }}
          >
            $
          </Typography>
          <Typography
            sx={{
              fontSize: 88,
              fontWeight: 400,
              lineHeight: "96px",
              fontFamily: "Darker Grotesque",
              opacity: loading ? 0 : 1,
            }}
          >
            {(data.balanceAvailable + data.balancePending).toFixed(2)}
          </Typography>
        </Box>
      </Box>
    </Link>
  );
}

export default YourTipsCard;
