import { Box, Grid, Typography } from "@mui/material";
import DoneSvg from "../../assets/images/done.svg";
import { StyledButton } from "../../components/styled";

function OnboardingConfirmation() {
  return (
    <>
      <Box py={15} textAlign="center">
        <img src={DoneSvg} width={208} height={166} alt="done" />
        <Typography variant="h5" mt={5}>
          Tips are waiting for you!
        </Typography>
        <Typography fontSize={20} color="secondary" mt={2}>
          Continue to your dashboard to finish identity and bank verification.
        </Typography>
      </Box>
      <Grid container sx={{ mt: 4 }} spacing={2}>
        <Grid item md={6} xs={12}>
          <StyledButton variant="outlined" fullWidth href="/">
            Go to Dashboard
          </StyledButton>
        </Grid>
        <Grid item md={6} xs={12}>
          <StyledButton
            variant="contained"
            fullWidth
            href="/onboarding/promo-code"
          >
            Enter promo code
          </StyledButton>
        </Grid>
      </Grid>
    </>
  );
}

export default OnboardingConfirmation;
