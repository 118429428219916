import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import OnboardingTemplate from "./OnboardingTemplate";
import { StyledTextField } from "../../components/styled";

import { ProfileService } from "../../api/profile/profile.service";

function BadgeName() {
  const navigate = useNavigate();
  const user = useSelector(({ account }) => account);
  const [validation, setValidation] = useState(false);
  const [badgeName, setBadgeName] = useState("");

  const handleContinue = async () => {
    setValidation(true);
    if (badgeName === "") return;
    try {
      const res = await ProfileService.saveNickname({
        nickname: badgeName.trim(),
      });
      console.log(res);
      navigate("/onboarding/photo");
    } catch (err) {
      console.log(err);
    }
  };
  const handleBack = () => {
    navigate("/onboarding/industry-and-job");
  };

  useEffect(() => {
    if (user) {
      setBadgeName(user.nickname || "");
    }
  }, [user]);

  return (
    <OnboardingTemplate
      currentStep={3}
      title="What does your nametag say?"
      description="Your Tipping Badge is essential for collecting tips. It will include your name, job, and photo. Let’s build your badge."
      banner={
        <Typography
          sx={{
            fontFamily: "Darker Grotesque",
            color: "#161215",
            textAlign: "center",
            lineHeight: "20px",
          }}
        >
          Keep it up! We had a member collect an additional $250 last week by
          using tipmate.
        </Typography>
      }
      handleContinue={handleContinue}
      handleBack={handleBack}
    >
      <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 0.5 }}>
        Name
      </Typography>
      <StyledTextField
        placeholder="Enter name..."
        sx={{ width: "100%" }}
        value={badgeName}
        error={validation && !badgeName}
        onChange={(e) => setBadgeName(e.target.value)}
      />
    </OnboardingTemplate>
  );
}

export default BadgeName;
