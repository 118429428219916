import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';

export const NAVS = [
  {
    label: "Dashboard",
    url: "/",
    icon: <HomeOutlinedIcon fontSize="inherit" />,
  },
  {
    label: "Rewards",
    url: "/rewards",
    icon: <MonetizationOnOutlinedIcon fontSize="inherit" />,
  },
  {
    label: "Transactions",
    url: "/transactions",
    icon: <HistoryOutlinedIcon fontSize="inherit" />,
  },
  {
    label: "Order Hardware",
    url: "/order-hardware",
    icon: <ShoppingCartOutlinedIcon fontSize="inherit" />,
  },
  {
    label: "Settings",
    url: "/settings",
    icon: <SettingsOutlinedIcon fontSize="inherit" />,
    children: [
      {
        label: "Profile",
        url: "/settings",
        icon: <AccountCircleOutlinedIcon fontSize="inherit" />,
      },
      {
        label: "Personal Information",
        url: "/settings#account_settings",
        icon: <SettingsApplicationsOutlinedIcon fontSize="inherit" />,
      },
      {
        label: "Linked Bank Accounts",
        url: "/settings#manage_accounts",
        icon: <AccountBalanceOutlinedIcon fontSize="inherit" />,
      },
      {
        label: "Employer Information",
        url: "/settings#employer_information",
        icon: <BusinessOutlinedIcon fontSize="inherit" />,
      },
      {
        label: "Notifications",
        url: "/settings#notification_preferences",
        icon: <NotificationsNoneOutlinedIcon fontSize="inherit" />,
      },
    ],
  },
];
