import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Snackbar,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import { AddAccountDialog } from "../AddAccountDialog";
import { ProfileService } from "../../api/profile/profile.service";
import { BankAccountData, CardData } from "../../types/auth";

function ManageAccounts() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<
    null | BankAccountData | CardData
  >(null);
  const [bankAccounts, setBankAccounts] = useState<BankAccountData[]>([]);
  const [debitCards, setDebitCards] = useState<CardData[]>([]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleAlertClose = () => {
    setSelectedAccount(null);
  };
  const handleRemove = async () => {
    setSelectedAccount(null);
    try {
      const res = await ProfileService.deleteExternalAccount(
        selectedAccount?.account || "",
        selectedAccount?.id || ""
      );
      console.log(res);
      setOpenAlert(true);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchAccounts = async () => {
    try {
      setLoading(true);
      const resb = await ProfileService.getBankAccount();
      setBankAccounts(resb.data);
      const resc = await ProfileService.getCards();
      setDebitCards(resc.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAccounts();
  }, []);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openAlert}
        autoHideDuration={6000}
        onClose={() => setOpenAlert(false)}
      >
        <Alert
          onClose={() => setOpenAlert(false)}
          variant="filled"
          severity="success"
          sx={{ width: "100%" }}
        >
          The payment method was removed successfully!
        </Alert>
      </Snackbar>
      <Button
        variant="outlined"
        startIcon={
          <AddIcon sx={{ width: "16px", height: "16px", color: "#A000BF" }} />
        }
        fullWidth
        sx={{
          fontSize: 14,
          lineHeight: "20px",
          padding: "10px 16px",
          borderRadius: "12px",
          boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        Add another account
      </Button>
      {loading ? (
        <Typography mt="40px">Loading...</Typography>
      ) : (
        <>
          {bankAccounts.length > 0 && (
            <Box mt="40px">
              <Typography variant="subtitle1">Bank Accounts</Typography>
              <List>
                {bankAccounts.map((item: BankAccountData) => (
                  <ListItem
                    key={item.id}
                    sx={{
                      padding: "4px 8px",
                      border: "1px solid #E2E8F0",
                      borderRadius: "4px",
                      background: "#fff",
                      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
                      mb: 2,
                    }}
                    secondaryAction={
                      !item.default_for_currency && (
                        <IconButton
                          edge="end"
                          color="primary"
                          onClick={() => setSelectedAccount(item)}
                        >
                          <DeleteOutlineIcon
                            sx={{ width: "20px", height: "20px" }}
                          />
                        </IconButton>
                      )
                    }
                  >
                    <ListItemAvatar>
                      <AccountBalanceOutlinedIcon
                        sx={{ width: 64, height: 64, mr: 2 }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            sx={{ fontSize: 16, fontWeight: 600, mb: 1 }}
                          >
                            {item.bank_name}
                          </Typography>
                          {item.default_for_currency && (
                            <Chip label="Default" color="primary" />
                          )}
                        </Box>
                      }
                      secondary={
                        <>
                          <Typography
                            component="span"
                            sx={{
                              fontSize: 12,
                              color: "#64748B",
                              display: "block",
                            }}
                          >
                            Account Number
                          </Typography>
                          <Typography
                            component="span"
                            sx={{
                              fontSize: 14,
                              fontWeight: 600,
                              color: "#334155",
                              display: "block",
                            }}
                          >
                            | {item.routing_number} | •••• {item.last4}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          {debitCards.length > 0 && (
            <Box mt="40px">
              <Typography variant="subtitle1">Debit Cards</Typography>
              <List>
                {debitCards.map((item: CardData) => (
                  <ListItem
                    key={item.id}
                    sx={{
                      padding: "4px 8px",
                      border: "1px solid #E2E8F0",
                      borderRadius: "4px",
                      background: "#fff",
                      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
                      mb: 2,
                    }}
                    secondaryAction={
                      !item.default_for_currency && (
                        <IconButton
                          edge="end"
                          color="primary"
                          onClick={() => setSelectedAccount(item)}
                        >
                          <DeleteOutlineIcon
                            sx={{ width: "20px", height: "20px" }}
                          />
                        </IconButton>
                      )
                    }
                  >
                    <ListItemAvatar>
                      <CreditCardIcon sx={{ width: 64, height: 64, mr: 2 }} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            sx={{ fontSize: 16, fontWeight: 600, mb: 1 }}
                          >
                            {item.brand}
                          </Typography>
                          {item.default_for_currency && (
                            <Chip
                              label="Default"
                              color="primary"
                              size="small"
                              sx={{ fontSize: 12, fontWeight: 400, ml: 1 }}
                            />
                          )}
                        </Box>
                      }
                      secondary={
                        <>
                          <Typography
                            component="span"
                            sx={{
                              fontSize: 12,
                              color: "#64748B",
                              display: "block",
                            }}
                          >
                            Card Number
                          </Typography>
                          <Typography
                            component="span"
                            sx={{
                              fontSize: 14,
                              fontWeight: 600,
                              color: "#334155",
                              display: "block",
                            }}
                          >
                            •••• •••• •••• {item.last4}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </>
      )}
      <AddAccountDialog
        open={open}
        handleClose={handleClose}
        fetchAccounts={fetchAccounts}
      />
      <Dialog
        open={!!selectedAccount}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Account?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to delete this account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClose}>Cancel</Button>
          <Button onClick={handleRemove}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ManageAccounts;
