import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Radio,
  Snackbar,
  Typography,
} from "@mui/material";
import { CustomInput } from "../components/CustomInput";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import { BootstrapDialog, StyledButton } from "../components/styled";

import { StripeService } from "../api/stripe/stripe.service";
import { ProfileService } from "../api/profile/profile.service";
import TransferMethodButton from "../components/TransferMethodButton";
import { BankAccountData, CardData } from "../types/auth";

const transferMethod = [
  {
    id: 1,
    title: "Instant Transfer",
    fee: "3.5% + 50¢",
  },
  {
    id: 2,
    title: "1-3 Day Transfer",
    fee: "Free",
  },
];
interface TransferMethodProps {
  id: number;
  title: string;
  fee: string;
}

function Transfer() {
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [bankAccounts, setBankAccounts] = useState<BankAccountData[]>([]);
  const [debitCards, setDebitCards] = useState<CardData[]>([]);
  const [amount, setAmount] = useState("0.00");
  const [fee, setFee] = useState(0);
  const [open, setOpen] = useState(false);
  const [openSnackbar, setSnackbarOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [selectedTransferMethod, setSelectedTransferMethod] =
    useState<TransferMethodProps>(transferMethod[0]);
  const [selectedAccount, setSelectedAccount] =
    useState<BankAccountData | null>(null);
  const [balance, setBalance] = useState({
    balanceAvailable: 0,
    balancePending: 0,
  });

  const handleClose = () => {
    setOpen(false);
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  const handleMax = () => {
    setAmount(balance.balanceAvailable.toFixed(2));
  };
  const handleTransfer = async () => {
    setAlertOpen(false);
    setProcessing(true);
    try {
      await StripeService.payout(
        amount,
        selectedAccount?.id || "",
        selectedTransferMethod.id === 1 ? "instant" : "standard"
      );
      setProcessing(false);
      setSnackbarOpen(true);
      await fetchBalanceData();
    } catch (e) {
      console.log(e);
      setProcessing(false);
    }
  };
  const handleChangeInput = (value: string) => {
    setAmount(value);
  };

  const fetchBalanceData = async () => {
    const data = await ProfileService.getBalance();
    setBalance(data);
  };
  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        setLoading(true);
        const resb = await ProfileService.getBankAccount();
        setBankAccounts(resb.data);
        const resc = await ProfileService.getCards();
        setDebitCards(resc.data);
        resb.data.forEach((item: BankAccountData) => {
          if (item.default_for_currency) {
            setSelectedAccount(item);
          }
        });
        resc.data.forEach((item: CardData) => {
          if (item.default_for_currency) {
            setSelectedAccount(item);
          }
        });
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    };
    fetchBalanceData();
    fetchAccounts();
  }, []);

  useEffect(() => {
    const fee =
      selectedTransferMethod.id === 1 ? parseFloat(amount) * 0.035 + 0.5 : 0;
    setFee(fee);
  }, [amount, selectedTransferMethod]);
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          The funds was sent to a user’s bank account or debit card
          successfully!
        </Alert>
      </Snackbar>
      <Button
        sx={{ fontSize: 14, fontWeight: 600 }}
        startIcon={<ArrowBackIcon sx={{ fontSize: "16px" }} />}
        href="/"
      >
        Back to Dashboard
      </Button>
      <Typography variant="h5" mt={2}>
        Cash Out
      </Typography>
      <Box
        sx={{ p: 2, border: "3px solid", backgroundColor: "#E6E0EE", mt: 2 }}
      >
        <Typography variant="h6" mb={1}>
          Pending: ${balance.balancePending.toFixed(2)}
        </Typography>
        <Typography variant="body2">
          Tips received will be available after 2 business days.
        </Typography>
      </Box>
      <Box
        sx={{
          mt: "24px",
          height: { md: "306px", xs: "254px" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CustomInput value={amount} setValue={handleChangeInput} />
        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          <Typography sx={{ fontSize: 12, color: "#64748B", mr: 1 }}>
            Available: ${balance.balanceAvailable.toFixed(2)}
          </Typography>
          <Button
            variant="text"
            sx={{
              padding: "4px",
              fontSize: 12,
              lineHeight: "16px",
              fontWeight: 600,
              minWidth: "42px",
            }}
            onClick={handleMax}
          >
            Max
          </Button>
        </Box>
      </Box>
      <Typography sx={{ mb: 3, textAlign: "center" }}>
        Free 1-3 day transfers
      </Typography>
      <Grid container spacing={2}>
        {transferMethod.map((item) => (
          <Grid key={item.id} item md={6} xs={6}>
            <TransferMethodButton
              title={item.title}
              fee={item.fee}
              selected={item.id === selectedTransferMethod.id}
              onClick={() => setSelectedTransferMethod(item)}
            />
          </Grid>
        ))}
      </Grid>
      {loading ? (
        <Typography mt={3}>Loading...</Typography>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
          <Typography
            sx={{ fontSize: 14, fontWeight: 600, color: "#64748B", mr: 2 }}
          >
            Transfer to
          </Typography>
          <Box
            sx={{
              flex: 1,
              padding: "4px 8px",
              borderRadius: "4px",
              backgroundColor: "#fff",
              boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {selectedAccount?.object === "bank_account" ? (
                <AccountBalanceOutlinedIcon
                  sx={{ width: 32, height: 32, borderRadius: "2px", mr: 1 }}
                />
              ) : (
                <CreditCardIcon
                  sx={{ width: 32, height: 32, borderRadius: "2px", mr: 1 }}
                />
              )}
              <Box>
                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                  {selectedAccount?.bank_name || selectedAccount?.brand}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: 12, color: "#334155" }}
                >
                  {selectedAccount?.object === "bank_account"
                    ? `| ${selectedAccount?.routing_number} | •••• ${selectedAccount?.last4}`
                    : `•••• •••• •••• ${selectedAccount?.last4}`}
                </Typography>
              </Box>
            </Box>
            <IconButton color="primary" onClick={() => setOpen(true)}>
              <EditOutlinedIcon sx={{ fontSize: 16 }} />
            </IconButton>
          </Box>
        </Box>
      )}
      {parseFloat(amount) - fee > 0 && (
        <Typography sx={{ color: "#64748B", mt: 5, textAlign: "center" }}>
          You’ll receive ${(parseFloat(amount) - fee).toFixed(2)} after fees
        </Typography>
      )}
      <StyledButton
        variant="contained"
        sx={{ marginTop: 2 }}
        fullWidth
        disabled={
          processing ||
          parseFloat(amount) < 1 ||
          parseFloat(amount) > balance.balanceAvailable
        }
        onClick={() => setAlertOpen(true)}
      >
        Transfer Funds
      </StyledButton>

      <BootstrapDialog
        onClose={handleClose}
        open={open}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Select Account</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            zIndex: 1001,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography sx={{ color: "#334155" }}>
            Which account do you want us to transfer your tips to?
          </Typography>
          <List sx={{ p: 0, mt: 2 }}>
            {bankAccounts.map((item: BankAccountData) => (
              <ListItemButton
                key={item.id}
                sx={{
                  padding: "4px 8px",
                  border: `1px solid ${
                    item.id === selectedAccount?.id ? "#3C383B" : "#E2E8F0"
                  }`,
                  borderRadius: "4px",
                  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
                  mb: 2,
                  background:
                    item.id === selectedAccount?.id
                      ? "#FED6FF !important"
                      : "#fff",
                }}
                selected={item.id === selectedAccount?.id}
                onClick={() => {
                  handleClose();
                  setSelectedAccount(item);
                }}
              >
                <ListItemAvatar>
                  <AccountBalanceOutlinedIcon
                    sx={{ width: 32, height: 32, borderRadius: "2px", mr: 1 }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: 14,
                        color:
                          item.id === selectedAccount?.id
                            ? "#7A0093"
                            : "#0F172A",
                        fontWeight: item.id === selectedAccount?.id ? 600 : 400,
                      }}
                    >
                      {item.bank_name}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      sx={{
                        fontSize: 12,
                        color:
                          item.id === selectedAccount?.id
                            ? "#7A0093"
                            : "#334155",
                      }}
                    >
                      | {item.routing_number} | •••• {item.last4}
                    </Typography>
                  }
                />
                <Radio
                  sx={{ pointerEvents: "none", p: 0 }}
                  checked={item.id === selectedAccount?.id}
                />
              </ListItemButton>
            ))}
            {debitCards.map((item: CardData) => (
              <ListItemButton
                key={item.id}
                sx={{
                  padding: "4px 8px",
                  border: `1px solid ${
                    item.id === selectedAccount?.id ? "#3C383B" : "#E2E8F0"
                  }`,
                  borderRadius: "4px",
                  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
                  mb: 2,
                  background:
                    item.id === selectedAccount?.id
                      ? "#FED6FF !important"
                      : "#fff",
                }}
                selected={item.id === selectedAccount?.id}
                onClick={() => {
                  handleClose();
                  setSelectedAccount(item);
                }}
              >
                <ListItemAvatar>
                  <CreditCardIcon
                    sx={{ width: 32, height: 32, borderRadius: "2px", mr: 1 }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: 14,
                        color:
                          item.id === selectedAccount?.id
                            ? "#7A0093"
                            : "#0F172A",
                        fontWeight: item.id === selectedAccount?.id ? 600 : 400,
                      }}
                    >
                      {item.brand}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      sx={{
                        fontSize: 12,
                        color:
                          item.id === selectedAccount?.id
                            ? "#7A0093"
                            : "#334155",
                      }}
                    >
                      •••• •••• •••• {item.last4}
                    </Typography>
                  }
                />
                <Radio
                  sx={{ pointerEvents: "none", p: 0 }}
                  checked={item.id === selectedAccount?.id}
                />
              </ListItemButton>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" href="/settings#manage_accounts">
            + Add bank account or debit card
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Dialog
        open={alertOpen}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Transfer</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure for transferring ${amount} to your{" "}
            {selectedAccount?.object === "bank_account"
              ? "bank account"
              : "debit card"}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClose}>Cancel</Button>
          <Button onClick={handleTransfer}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Transfer;
