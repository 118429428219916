import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Link,
  Typography,
} from "@mui/material";
import ReactPhoneInput from "react-phone-input-material-ui";
import "react-phone-input-material-ui/lib/style.css";
import OnboardingTemplate from "./OnboardingTemplate";
import { PhoneInput, StyledTextField } from "../../components/styled";

import PrivacyPolicyDoc from "../../assets/docs/2023-12-20 Privacy Policy.pdf";
import ToSDoc from "../../assets/docs/2023-12-20 Terms of Service.pdf";
import { ProfileService } from "../../api/profile/profile.service";
import { AppState } from "../../store/reducers";
import { UserData } from "../../types/auth";

function NameAndNumber() {
  const navigate = useNavigate();
  const user = useSelector<AppState, UserData | null>(
    ({ account }) => account.user
  );
  const [agreed, setAgreed] = useState(false);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    smsNotify: false,
    emailNotify: false,
  });
  const [validation, setValidation] = useState(false);
  const handleContinue = async () => {
    setValidation(true);
    if (
      data.firstName === "" ||
      data.lastName === "" ||
      data.phoneNumber === ""
    ) {
      return;
    }
    try {
      await ProfileService.saveInformation({
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        smsNotify: data.smsNotify,
        emailNotify: data.emailNotify,
      });
      await sendSMSVerification();
      navigate("/onboarding/two-factor");
    } catch (err) {
      console.log(err);
    }
  };
  const sendSMSVerification = async () => {
    try {
      const response = await ProfileService.sendSmsCode(data.phoneNumber);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = async (e: any) => {
    setValidation(false);
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (user) {
      setData({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        phoneNumber: user.phoneNumber || "",
        smsNotify: user.smsNotify || false,
        emailNotify: user.emailNotify || false,
      });
    }
  }, [user]);

  return (
    <OnboardingTemplate
      currentStep={1}
      title="Personal Information"
      description="TipMate needs to collect some personal information to create a wallet and send you tips."
      banner={
        <Typography
          sx={{
            fontFamily: "Darker Grotesque",
            color: "#161215",
            textAlign: "center",
            lineHeight: "20px",
          }}
        >
          TipMate has collected $50,000 in tips for members this month. Can you
          smell the money?
        </Typography>
      }
      handleContinue={handleContinue}
      disableContinue={!agreed}
    >
      <Box mb={3}>
        <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 0.5 }}>
          First Name
        </Typography>
        <StyledTextField
          placeholder="Enter first name"
          sx={{ width: "100%" }}
          name="firstName"
          value={data.firstName}
          error={validation && !data.firstName}
          onChange={handleChange}
        />
        <Typography color="secondary" sx={{ fontSize: 12, mt: 0.5 }}>
          Enter as shown on government ID or bank account
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 0.5 }}>
          Last Name
        </Typography>
        <StyledTextField
          placeholder="Enter last name"
          sx={{ width: "100%" }}
          name="lastName"
          value={data.lastName}
          error={validation && !data.lastName}
          onChange={handleChange}
        />
        <Typography color="secondary" sx={{ fontSize: 12, mt: 0.5 }}>
          Enter as shown on government ID or bank account
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 0.5 }}>
          Phone Number
        </Typography>
        <ReactPhoneInput
          country="us"
          label=""
          value={data.phoneNumber}
          component={PhoneInput}
          buttonStyle={{ left: "10px" }}
          inputProps={{
            name: "phoneNumber",
            error: validation && !data.phoneNumber,
          }}
          onChange={(value) =>
            handleChange({
              target: { name: "phoneNumber", value },
            })
          }
        />
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={data.smsNotify}
            onChange={(e) =>
              setData((prev) => ({
                ...prev,
                smsNotify: e.target.checked,
                emailNotify: e.target.checked,
              }))
            }
          />
        }
        label={
          <>
            <Typography
              variant="body1"
              component="span"
              sx={{ color: "#334155" }}
            >
              I agree to receive carrier text messages and emails from Tipmate
              regarding incoming tips and other important account updates.
            </Typography>
            <Typography
              variant="body2"
              component="span"
              sx={{ color: "#334155", ml: 1 }}
            >
              Message and data rates may apply.
            </Typography>
          </>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={agreed}
            onChange={() => setAgreed((prev) => !prev)}
          />
        }
        label={
          <>
            <Typography sx={{ color: "#334155" }}>
              I have read and agree to our{" "}
              <Link
                href={ToSDoc}
                target="_blank"
                sx={{ textDecoration: "none", fontWeight: 600 }}
              >
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link
                href={PrivacyPolicyDoc}
                target="_blank"
                sx={{ textDecoration: "none", fontWeight: 600 }}
              >
                Privacy Policy
              </Link>
              .
            </Typography>
          </>
        }
      />
    </OnboardingTemplate>
  );
}

export default NameAndNumber;
