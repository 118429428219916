import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import OnboardingTemplate from "./OnboardingTemplate";
import { VisuallyHiddenInput } from "../../components/styled";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { ProfileService } from "../../api/profile/profile.service";
import { AuthService } from "../../api/auth/auth.service";
import { updateUser } from "../../store/reducers/account";

function Photo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [cropper, setCropper] = useState<Cropper>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>(null);

  const tips = [
    {
      title: "Picture Perfect",
      description:
        "Take a close-up shot of your face, much like a driver’s license or passport photo",
    },
    {
      title: "Lighting is Key",
      description:
        "Ensure the lighting is even and natural. We recommend taking the photo in daylight without using flash to avoid any glare.",
    },
    {
      title: "Stay Original",
      description:
        "Avoid using any filters or effects. We love the real you, and so will your tippers!",
    },
    {
      title: "Smile",
      description:
        "Flash those pearly whites like you’ve just received a generous tip.",
    },
    {
      title: "Backdrop Matters",
      description:
        "Have your photo taken against a white or light-colored background. A plain white wall is perfect.",
    },
    {
      title: "Stay Centered",
      description:
        "Ensure your face is centered and you are looking straight at the camera.",
    },
    {
      title: "Dress Professionally",
      description:
        "While only your face and shoulders are shown on your badge, we recommend dressing in the same way you would go to work is best.",
    },
    {
      title: "Check Twice, Upload Once",
      description:
        "Before uploading, review your photo to ensure it adheres to all guidelines. Remember, you won’t be able to resubmit after the photo is submitted.",
    },
  ];

  const handleContinue = async () => {
    if (!uploadedFile) return;
    try {
      setUploading(true);
      const formData = new FormData();
      cropper &&
        cropper.getCroppedCanvas().toBlob(async (blob: any) => {
          formData.append("image", blob);
          const res = await ProfileService.uploadPhoto(formData);
          const data = await AuthService.getUser();
          dispatch(updateUser(data));
          console.log("Image URL:", res.imageUrl);
          navigate("/onboarding/confirm-photo");
        });
    } catch (error) {
      console.error("Error uploading image:", error);
      setUploading(false);
    }
  };
  const handleBack = () => {
    navigate("/onboarding/badge-name");
  };
  const onFileUpload = async (e: any) => {
    if (e.target.files.length === 0) {
      setUploadedFile(null);
      return;
    }
    const file = e.target.files[0];
    setUploadedFile(file);
  };
  const handleCancel = () => {
    setUploadedFile(null);
    if (ref.current) {
      ref.current.value = null;
    }
  };

  return (
    <OnboardingTemplate
      currentStep={4}
      title="Upload a Photo"
      description="This is the photo Tippers will see on your badge prior to tipping. Follow the guidelines below carefully to ensure a successful creation."
      banner={
        <Typography
          sx={{
            fontFamily: "Darker Grotesque",
            lineHeight: "20px",
            color: "#161215",
            textAlign: "center",
          }}
        >
          It’s a fact, a smile can help you collect more tips. So go ahead and
          show those pearly whites!
        </Typography>
      }
      disableContinue={!uploadedFile || uploading}
      handleContinue={handleContinue}
      handleBack={handleBack}
    >
      <List sx={{ p: 0 }}>
        {tips.map((tip, index) => (
          <ListItem
            key={index}
            sx={{
              "&:not(:first-of-type)": {
                mt: 2,
              },
              padding: "8px 16px",
              borderRadius: "8px",
              boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
              background: "#E2E8F0",
            }}
          >
            <ListItemText
              sx={{ m: 0, color: "#251726" }}
              primary={
                <Typography variant="body2" sx={{ fontWeight: 600, mb: "3px" }}>
                  {tip.title}
                </Typography>
              }
              secondary={
                <Typography variant="body2">{tip.description}</Typography>
              }
            />
          </ListItem>
        ))}
      </List>
      {!!uploadedFile && (
        <Box>
          <Cropper
            src={URL.createObjectURL(uploadedFile)}
            style={{ height: 300, width: 400, margin: "32px auto" }}
            aspectRatio={200 / 250}
            initialAspectRatio={200 / 250}
            viewMode={1}
            minCropBoxHeight={1}
            minCropBoxWidth={1}
            background={false}
            responsive={true}
            zoomable={false}
            guides={false}
            checkOrientation={false}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />
          <Box textAlign="right">
            <Button variant="text" size="small" onClick={handleCancel}>
              Cancel
            </Button>
          </Box>
        </Box>
      )}
      <Typography variant="body2" mt={4}>
        Upload photo in portrait orientation. (Not landscape)
      </Typography>
      <Button
        component="label"
        variant="text"
        fullWidth
        sx={{
          mt: 2,
          background: "#FFF",
          padding: "16px 24px",
          lineHeight: "24px",
          borderRadius: "16px",
          boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
        }}
        disabled={!!uploadedFile}
      >
        {uploadedFile ? "Photo Uploaded" : "Upload Photo"}
        <VisuallyHiddenInput
          ref={ref}
          type="file"
          onChange={onFileUpload}
          accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
        />
      </Button>
    </OnboardingTemplate>
  );
}

export default Photo;
