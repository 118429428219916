import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Container,
  MenuItem,
  SelectChangeEvent,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { StyledButton, StyledSelect, StyledTab } from "../components/styled";
import PersonalInformation, {
  IPersonalInfo,
} from "../components/settings/PersonalInformation";
import ManageAccounts from "../components/settings/ManageAccounts";
import NotificationPreferences from "../components/settings/NotificationPreferences";
import EmployerInformation from "../components/settings/EmployerInformation";
import _ from "lodash";
import { updateUser } from "../store/reducers/account";
import { useLocation } from "react-router-dom";
import AccountSettings from "../components/settings/AccountSettings";
import { ProfileService } from "../api/profile/profile.service";
import { AuthService } from "../api/auth/auth.service";
import { AppState } from "../store/reducers";
import { UserData } from "../types/auth";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const initialEmployerData = {
  selfEmployed: false,
  employer: {
    name: "",
    website: "",
    address: "",
  },
  manager: {
    name: "",
    phoneNumber: "",
    email: "",
  },
};
const initialPersonalData: IPersonalInfo = {
  name: "",
  photoURL: "",
  jobs: [],
  message: "",
  bio: "",
  tipAmounts: Array<number>(),
};
const initialNotifyData = {
  sms: false,
  email: false,
};

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function Settings() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { hash } = useLocation();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const user = useSelector<AppState, UserData | null>(
    ({ account }) => account.user
  );
  const [value, setValue] = useState(0);
  const [changed, setChanged] = useState(false);
  const [originalData, setOriginalData] = useState({
    employer: initialEmployerData,
    personal: initialPersonalData,
    notify: initialNotifyData,
  });
  const [employer, setEmployer] = useState(initialEmployerData);
  const [personal, setPersonal] = useState(initialPersonalData);
  const [notify, setNotify] = useState(initialNotifyData);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      window.location.hash = "";
    } else if (newValue === 1) {
      window.location.hash = "account_settings";
    } else if (newValue === 2) {
      window.location.hash = "manage_accounts";
    } else if (newValue === 3) {
      window.location.hash = "employer_information";
    } else if (newValue === 4) {
      window.location.hash = "notification_preferences";
    }
  };
  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    setValue(Number(event.target.value));
  };
  const resetSettingsData = () => {
    setChanged(false);
    if (user) {
      setPersonal({
        name: `${user.firstName} ${user.lastName}`,
        photoURL: user.photoURL || "",
        jobs: user.jobs || [],
        message: user.message || "",
        bio: user.bio || "",
        tipAmounts: user.tipAmounts || [3, 5, 10],
      });
      setNotify({
        sms: user.smsNotify || false,
        email: user.emailNotify || false,
      });
      setOriginalData({
        ...originalData,
        personal: {
          name: `${user.firstName} ${user.lastName}`,
          photoURL: user.photoURL || "",
          jobs: user.jobs || [],
          message: user.message || "",
          bio: user.bio || "",
          tipAmounts: user.tipAmounts || [3, 5, 10],
        },
        notify: { sms: !!user.smsNotify, email: !!user.emailNotify },
      });
    }
    if (user?.employer && Object.keys(user?.employer).length) {
      setEmployer({ ...employer, employer: user.employer });
      setOriginalData({
        ...originalData,
        employer: { ...originalData.employer, employer: user.employer },
      });
    }
    if (user?.manager && Object.keys(user?.manager).length) {
      setEmployer({ ...employer, manager: user.manager });
      setOriginalData({
        ...originalData,
        employer: { ...originalData.employer, manager: user.manager },
      });
    }
  };
  const compareChanges = () => {
    if (
      !_.isEqual(originalData.employer, employer) ||
      !_.isEqual(originalData.personal, personal) ||
      !_.isEqual(originalData.notify, notify)
    ) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  };
  const handleSaveChanges = async () => {
    if (personal.jobs.length === 0) {
      alert("You have to select at least one job!");
      return;
    }
    if (personal.tipAmounts.includes(0)) {
      alert("Please input the preset tip amount!");
      return;
    }
    const res = await ProfileService.saveInformation({
      ...employer,
      smsNotify: notify.sms,
      emailNotify: notify.email,
      jobs: personal.jobs,
      message: personal.message,
      bio: personal.bio,
      tipAmounts: personal.tipAmounts,
    });
    console.log(res);
    const data = await AuthService.getUser();
    dispatch(updateUser(data));
  };

  useEffect(() => {
    resetSettingsData();
  }, [user]);

  useEffect(() => {
    compareChanges();
  }, [employer, personal, notify]);

  useEffect(() => {
    if (hash === "" || hash === "#personal_information") {
      setValue(0);
    } else if (hash === "#account_settings") {
      setValue(1);
    } else if (hash === "#manage_accounts") {
      setValue(2);
    } else if (hash === "#employer_information") {
      setValue(3);
    } else if (hash === "#notification_preferences") {
      setValue(4);
    }
  }, [hash]);

  return (
    <>
      <Button
        sx={{ fontSize: 14, fontWeight: 600 }}
        startIcon={<ArrowBackIcon sx={{ fontSize: "16px" }} />}
        href="/"
      >
        Back to Dashboard
      </Button>
      <Typography variant="h5" my={1}>
        Settings
      </Typography>
      <Box mb={5}>
        <Box sx={{ marginBottom: "40px" }}>
          {matches ? (
            <Tabs
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{ hidden: true }}
            >
              <StyledTab label="Profile" />
              <StyledTab label="Personal Information" />
              <StyledTab label="Linked Bank Accounts" />
              <StyledTab label="Employer Information" />
              <StyledTab label="Notifications" />
            </Tabs>
          ) : (
            <StyledSelect
              fullWidth
              value={value.toString()}
              onChange={handleSelectChange}
            >
              <MenuItem value={0}>Profile</MenuItem>
              <MenuItem value={1}>Personal Information</MenuItem>
              <MenuItem value={2}>Linked Bank Accounts</MenuItem>
              <MenuItem value={3}>Employer Information</MenuItem>
              <MenuItem value={4}>Notifications</MenuItem>
            </StyledSelect>
          )}
        </Box>
        <CustomTabPanel value={value} index={0}>
          <PersonalInformation data={personal} setData={setPersonal} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <AccountSettings />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <ManageAccounts />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <EmployerInformation data={employer} setData={setEmployer} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <NotificationPreferences data={notify} setData={setNotify} />
        </CustomTabPanel>
      </Box>
      {changed && (
        <Box
          sx={{
            width: "100%",
            position: "fixed",
            bottom: "8px",
            left: 0,
          }}
        >
          <Container
            sx={{
              maxWidth: "816px !important",
              paddingX: { md: "40px", xs: "24px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { md: "row", xs: "column" },
                justifyContent: { md: "space-between", xs: "center" },
                alignItems: "center",
                backgroundColor: "#fff",
                boxShadow: "0px 16px 24px 0px rgba(0, 0, 0, 0.15)",
                borderRadius: 1,
                padding: 1,
              }}
            >
              <Box
                sx={{
                  display: { md: "flex", xs: "none" },
                  alignItems: "center",
                }}
              >
                <WarningAmberIcon
                  sx={{
                    width: "24px",
                    height: "24px",
                    color: "#CA8A04",
                    mr: 1,
                  }}
                />
                <Typography variant="body2" color="#CA8A04">
                  You have unsaved changes
                </Typography>
              </Box>
              <Box>
                <StyledButton
                  variant="text"
                  sx={{
                    padding: "10px 16px",
                    fontSize: 14,
                    lineHeight: "20px",
                    mr: 3,
                  }}
                  onClick={resetSettingsData}
                >
                  Discard Changes
                </StyledButton>
                <StyledButton
                  variant="contained"
                  sx={{
                    fontSize: 14,
                    padding: "10px 16px",
                    lineHeight: "20px",
                  }}
                  onClick={handleSaveChanges}
                >
                  Save Changes
                </StyledButton>
              </Box>
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
}

export default Settings;
