import { createTheme } from "@mui/material/styles";

// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            main: "#221E22",
        },
        secondary: {
            main: "#6B596B",
        },
        info: {
            main: "#FFFFFF",
        },
        text: {
            primary: "#1E1A1E",
            secondary: "#6B596B",
        },
        background: {
            default: "#F1F5F9",
            paper: "#FFFFFF",
        },
    },
    typography: {
        fontFamily: "Inter",
        fontSize: 16,
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: 16,
                },
                body1: {
                    fontSize: 16,
                },
                body2: {
                    fontSize: 14,
                },
                subtitle1: {
                    fontWeight: 600,
                    fontSize: 18,
                },
                h2: {
                    fontWeight: 600,
                    fontSize: 64,
                },
                h3: {
                    fontWeight: 600,
                    fontSize: 48,
                },
                h5: {
                    fontWeight: 600,
                    fontSize: 24,
                },
                h6: {
                    fontWeight: 600,
                    fontSize: 20,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                },
                contained: {
                    fontWeight: 400,
                    border: 0
                },
                outlined: {
                    color: "#161215",
                    fontWeight: 500,
                    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)"
                },
                text: {
                    fontWeight: 600
                }
            }
        }
    }
});

export default theme;
