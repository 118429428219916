import { Box, MenuItem, Typography } from "@mui/material";
import { StyledSelect, StyledTextField } from "../../components/styled";
import { BankAccountData } from "../../types/auth";

interface BankAccountProps {
  data: BankAccountData;
  validation: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChange: (e: any) => void;
}

function BankAccount({ data, validation, handleChange }: BankAccountProps) {
  return (
    <>
      <Box mb={3}>
        <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 0.5 }}>
          Country
        </Typography>
        <StyledSelect
          name="country"
          value={data.country}
          fullWidth
          onChange={handleChange}
        >
          <MenuItem value="US">United States</MenuItem>
          <MenuItem value="CA">Canada</MenuItem>
          <MenuItem value="GB">United Kingdom</MenuItem>
          <MenuItem value="DE">Germany</MenuItem>
          <MenuItem value="FR">France</MenuItem>
          <MenuItem value="ES">Spain</MenuItem>
        </StyledSelect>
      </Box>
      <Box mb={3}>
        <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 0.5 }}>
          Currency
        </Typography>
        <StyledSelect
          name="currency"
          value={data.currency}
          fullWidth
          onChange={handleChange}
        >
          <MenuItem value="USD">US Dollar</MenuItem>
          <MenuItem value="CAD">Canadian Dollar</MenuItem>
          <MenuItem value="GBP">Pound</MenuItem>
          <MenuItem value="EUR">Euro</MenuItem>
        </StyledSelect>
      </Box>
      <Box mb={3}>
        <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 0.5 }}>
          Routing Number
        </Typography>
        <StyledTextField
          placeholder="Routing Number"
          sx={{ width: "100%" }}
          name="routing_number"
          error={validation && !data.routing_number}
          onChange={handleChange}
        />
      </Box>
      <Box mb={3}>
        <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 0.5 }}>
          Account Number
        </Typography>
        <StyledTextField
          placeholder="Account Number"
          sx={{ width: "100%" }}
          name="account_number"
          error={validation && !data.account_number}
          onChange={handleChange}
        />
      </Box>
      <Box mb={3}>
        <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 0.5 }}>
          Account Holder Name
        </Typography>
        <StyledTextField
          placeholder="Account Holder Name"
          sx={{ width: "100%" }}
          name="account_holder_name"
          error={validation && !data.account_holder_name}
          onChange={handleChange}
        />
      </Box>
      <Box mb={3}>
        <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 0.5 }}>
          Account Holder Type
        </Typography>
        <StyledSelect
          name="account_holder_type"
          value={data.account_holder_type}
          fullWidth
          onChange={handleChange}
        >
          <MenuItem value="individual">Individual</MenuItem>
          <MenuItem value="company">Company</MenuItem>
        </StyledSelect>
      </Box>
    </>
  );
}

export default BankAccount;
