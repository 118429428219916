import { useEffect, useState } from "react";
import {
  Box,
  Button,
  List,
  Pagination,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CheckIcon from "@mui/icons-material/Check";
import { StyledChip, StyledMenuItem } from "../components/styled";
import { TransactionHistoryItem } from "../components/TransactionHistoryItem";
import { TransactionData } from "../types/transaction";
import { ProfileService } from "../api/profile/profile.service";

function Transactions() {
  const [filter, setFilter] = useState("All time");
  const [loading, setLoading] = useState(false);
  const [transaction, setTransaction] = useState<TransactionData[]>([]);
  const [pending, setPending] = useState<TransactionData[]>([]);
  const [completed, setCompleted] = useState<TransactionData[]>([]);
  const perPage = 5;
  const [pendingPage, setPendingPage] = useState(1);
  const [page, setPage] = useState(1);

  const handleChange = (event: SelectChangeEvent) => {
    setFilter(event.target.value);
    if (event.target.value === "All time") {
      setPending(
        transaction.filter((x: TransactionData) => x.status === "pending")
      );
      setCompleted(
        transaction.filter((x: TransactionData) => x.status === "available")
      );
    } else if (event.target.value === "Week") {
      const date = new Date();
      date.setDate(date.getDate() - (date.getDay() || 7) + 1);
      const filtered = transaction.filter(
        (x: TransactionData) =>
          x.created * 1000 > date.getTime() && x.created * 1000 < Date.now()
      );
      setPending(
        filtered.filter((x: TransactionData) => x.status === "pending")
      );
      setCompleted(
        filtered.filter((x: TransactionData) => x.status === "available")
      );
    } else if (event.target.value === "Month") {
      let date = new Date();
      date = new Date(date.getFullYear(), date.getMonth(), 1);
      const filtered = transaction.filter(
        (x: TransactionData) =>
          x.created * 1000 > date.getTime() && x.created * 1000 < Date.now()
      );
      setPending(
        filtered.filter((x: TransactionData) => x.status === "pending")
      );
      setCompleted(
        filtered.filter((x: TransactionData) => x.status === "available")
      );
    } else if (event.target.value === "Year") {
      let date = new Date();
      date = new Date(date.getFullYear(), 0, 1);
      const filtered = transaction.filter(
        (x: TransactionData) =>
          x.created * 1000 > date.getTime() && x.created * 1000 < Date.now()
      );
      setPending(
        filtered.filter((x: TransactionData) => x.status === "pending")
      );
      setCompleted(
        filtered.filter((x: TransactionData) => x.status === "available")
      );
    }
  };
  const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  useEffect(() => {
    const fetchTransactionHistory = async () => {
      try {
        setLoading(true);
        const res = await ProfileService.getTransactions();
        const transactions = res.data.filter(
          (x: TransactionData) => x.type === "payout" || x.type === "payment"
        );
        setTransaction(transactions);
        setPending(
          transactions.filter((x: TransactionData) => x.status === "pending")
        );
        setCompleted(
          transactions.filter((x: TransactionData) => x.status === "available")
        );
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    };
    fetchTransactionHistory();
  }, []);

  return (
    <>
      <Button
        sx={{ fontSize: 14, fontWeight: 600 }}
        startIcon={<ArrowBackIcon sx={{ fontSize: "16px" }} />}
        href="/"
      >
        Back to Dashboard
      </Button>
      <Typography variant="h5" mt={1}>
        Transaction History
      </Typography>
      <Box my="40px">
        <Typography
          sx={{ fontSize: 14, fontWeight: 600, color: "#64748B", mb: 0.5 }}
        >
          Filter Transactions
        </Typography>
        <Select
          value={filter}
          onChange={handleChange}
          startAdornment={<CalendarTodayIcon sx={{ fontSize: 24, mr: 1.5 }} />}
          fullWidth
          sx={{
            padding: "12px",
            backgroundColor: "#fff",
            borderRadius: "12px",
            border: "0.5px solid rgba(0, 0, 0, 0.10)",
            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
            fontSize: 16,
            color: "#334155",
            "& .MuiSvgIcon-root": {
              fill: "#94A3B8",
            },
            "& .MuiSelect-select": {
              paddingY: 0,
            },
          }}
        >
          <StyledMenuItem value="All time">All time</StyledMenuItem>
          <StyledMenuItem value="Week">This Week</StyledMenuItem>
          <StyledMenuItem value="Month">This Month</StyledMenuItem>
          <StyledMenuItem value="Year">This Year</StyledMenuItem>
        </Select>
      </Box>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <>
          <Box mt={4}>
            <StyledChip
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CheckIcon sx={{ fontSize: 16 }} />
                  <Typography fontSize={12} lineHeight="16px">
                    Pending
                  </Typography>
                </Box>
              }
              sx={{
                backgroundColor: "#DBEAFE",
                color: "#3B82F6",
                mb: 2,
              }}
            />
            {pending.length > 0 ? (
              <>
                <List>
                  {pending
                    .slice(perPage * (pendingPage - 1), perPage * pendingPage)
                    .map((item: TransactionData) => (
                      <TransactionHistoryItem key={item.id} data={item} />
                    ))}
                </List>
                <Stack alignItems="center">
                  <Pagination
                    count={Math.ceil(pending.length / perPage)}
                    page={pendingPage}
                    onChange={(_e, value) => setPendingPage(value)}
                  />
                </Stack>
              </>
            ) : (
              <Typography
                variant="body2"
                color="secondary"
                sx={{ mx: 2, fontStyle: "italic" }}
              >
                No pending transaction history yet.
              </Typography>
            )}
          </Box>
          <Box mt={4}>
            <StyledChip
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CheckIcon sx={{ fontSize: 16 }} />
                  <Typography fontSize={12} lineHeight="16px">
                    Completed
                  </Typography>
                </Box>
              }
              sx={{
                backgroundColor: "#D1FAE5",
                color: "#10B981",
                mb: 2,
              }}
            />
            {completed.length > 0 ? (
              <>
                <List>
                  {completed
                    .slice(perPage * (page - 1), perPage * page)
                    .map((item: TransactionData) => (
                      <TransactionHistoryItem key={item.id} data={item} />
                    ))}
                </List>
                <Stack alignItems="center">
                  <Pagination
                    count={Math.ceil(completed.length / perPage)}
                    page={page}
                    onChange={handlePageChange}
                  />
                </Stack>
              </>
            ) : (
              <Typography
                variant="body2"
                color="secondary"
                sx={{ mx: 2, fontStyle: "italic" }}
              >
                No transaction history yet.
              </Typography>
            )}
          </Box>
        </>
      )}
    </>
  );
}

export default Transactions;
