import { Button, Chip, Container, Dialog, ListItemButton, MenuItem, Select, Tab, TextField, TextareaAutosize, ToggleButton, ToggleButtonGroup, styled } from "@mui/material";

export const MainWrapper = styled(Container)({
    maxWidth: '1248px !important',
    color: '#1E1A1E !important'
})

export const ContentWrapper = styled(Container)({
    maxWidth: '816px !important',
    color: '#1E1A1E !important'
})

export const StyledTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        borderRadius: '12px',
        border: '0.5px solid rgba(0, 0, 0, 0.10)',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.10)',
        color: '#334155',
        fontSize: '16px',
        '& input': {
            borderRadius: '12px',
            backgroundColor: '#fff',
            padding: '12px',
        }
    }
})

export const PhoneInput = styled(TextField)({
    '& .MuiInputBase-root': {
        borderRadius: '12px',
        border: '0.5px solid rgba(0, 0, 0, 0.10)',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.10)',
        color: '#334155',
        fontSize: '16px',
        '& input': {
            borderRadius: '12px',
            backgroundColor: '#fff',
            padding: '12px 12px 12px 48px',
        }
    }
})

export const StyledButton = styled(Button)({
    padding: "16px 24px",
    lineHeight: "24px",
    borderRadius: "12px",
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.10)'
})

export const StyledListItemButton = styled(ListItemButton)({
    backgroundColor: '#fff',
    borderRadius: 8,
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.10)',
    marginBottom: 16,
    padding: '4px 16px 4px 8px'
})

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogTitle-root': {
        padding: theme.spacing(4),
        paddingBottom: 0
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(3),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(3),
    },
}))

export const StyledToggleButton = styled(ToggleButton)({
    width: '80px',
    height: '80px',
    border: "2px solid #E2E8F0 !important",
    borderRadius: '100%',
    backgroundColor: '#fff',
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)"
})

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
    display: 'flex',
    justifyContent: 'center',
    '& .MuiToggleButtonGroup-grouped': {
        border: 0,
        '&:not(:first-of-type)': {
            borderRadius: '100%',
            marginLeft: '32px'
        },
        '&:first-of-type': {
            borderRadius: '100%',
        },
    },
})

export const StyledTab = styled(Tab)({
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    color: '#64748B',
    padding: '4px 12px',
    borderRadius: '2px',
    minHeight: '40px',
    '&.Mui-selected': {
        fontWeight: 600,
    }
})

export const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
})

export const StyledTextarea = styled(TextareaAutosize)({
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: 1.5,
    padding: '12px',
    borderRadius: '12px',
    color: "#334155",
    background: '#fff',
    border: '0.5px solid rgba(0, 0, 0, 0.10)',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.10)',
    width: '100%',

    '&:hover': {
        borderColor: '#3B82F6'
    },

    '&:focus': {
        borderColor: '#3B82F6',
        boxShadow: '0 0 0 3px #b6daff'
    },

    '&:focus-visible': {
        outline: 0
    }
})

export const StyledChip = styled(Chip)({
    fontSize: 12,
    lineHeight: '16px',
    height: '20px',
    padding: "2px 5px 2px 4px",
    "& .MuiChip-label": {
        padding: 0,
    },
})

export const StyledMenuItem = styled(MenuItem)({
    "&:hover": {
        background: "rgba(0, 0, 0, 0.04)",
    },
})

export const StyledSelect = styled(Select)({
    backgroundColor: "#fff",
    borderRadius: "12px",
    border: "0.5px solid rgba(0, 0, 0, 0.10)",
    fontSize: 16,
    color: "#334155",
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
    "& .MuiSelect-select": { paddingTop: "12px", paddingBottom: "12px" },
})
