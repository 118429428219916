import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomAutoInput from "../../components/CustomAutoInput";
import OnboardingTemplate from "./OnboardingTemplate";
import { useEffect, useState } from "react";

import jobs from "../../constants/jobs.json";
import { useSelector } from "react-redux";
import { ProfileService } from "../../api/profile/profile.service";

function IndustryAndJob() {
  const navigate = useNavigate();
  const user = useSelector(({ account }) => account);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const handleContinue = async () => {
    const res = await ProfileService.saveJobs({
      jobs: selectedJobs,
    });
    console.log(res);
    navigate("/onboarding/badge-name");
  };
  const handleBack = () => {
    navigate("/onboarding/personal-information");
  };

  useEffect(() => {
    if (user) {
      setSelectedJobs(user.jobs || []);
    }
  }, [user]);

  return (
    <OnboardingTemplate
      currentStep={2}
      title="What do you do?"
      description="Tell us a bit more about your job and industry."
      banner={
        <Box>
          <Typography
            sx={{
              fontFamily: "Darker Grotesque",
              color: "#161215",
              textAlign: "center",
              lineHeight: "20px",
            }}
          >
            Did you know? Collecting digital tips you can increase your paycheck
            by as much as 40%!
          </Typography>
        </Box>
      }
      disableContinue={selectedJobs.length === 0}
      handleContinue={handleContinue}
      handleBack={handleBack}
    >
      <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 0.5 }}>
        Your job
      </Typography>
      <CustomAutoInput
        options={jobs}
        selected={selectedJobs}
        setSelected={setSelectedJobs}
      />
    </OnboardingTemplate>
  );
}

export default IndustryAndJob;
