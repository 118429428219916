import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";

interface INotification {
  sms: boolean;
  email: boolean;
}
interface NotificationPreferencesProps {
  data: INotification;
  setData: (data: INotification) => void;
}

function NotificationPreferences({
  data,
  setData,
}: NotificationPreferencesProps) {
  return (
    <>
      <Typography variant="subtitle1" mb={2}>
        Notifications
      </Typography>
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={data.sms}
              onChange={(e) =>
                setData({
                  ...data,
                  sms: e.target.checked,
                })
              }
            />
          }
          label={<Typography>SMS notification</Typography>}
        />
      </Box>
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={data.email}
              onChange={(e) =>
                setData({
                  ...data,
                  email: e.target.checked,
                })
              }
            />
          }
          label={<Typography>Email notification</Typography>}
        />
      </Box>
    </>
  );
}

export default NotificationPreferences;
