import { Alert, Box, Grid, Snackbar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { StyledButton, StyledTextField } from "../../components/styled";
 
import { useState } from "react";
import { ProfileService } from "../../api/profile/profile.service";

function PromoCode() {
  const navigate = useNavigate();
  const [promoCode, setPromoCode] = useState("");
  const [alertType, setAlertType] = useState<"error" | "success">("error");
  const [notification, setNotification] = useState("");
  const [open, setOpen] = useState(false);

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleSkip = () => {
    navigate("/");
  };
  const handleSubmit = async () => {
    const data = await ProfileService.checkPromoCode(promoCode.trim());
    console.log(data);
    if (data.isValid) {
      console.log(data);
      setNotification("Congrats! Your account has been activated.");
      setAlertType("success");
      setOpen(true);
      navigate("/");
    } else {
      console.log(data);
      setNotification("The provided promo code is not working.");
      setAlertType("error");
      setOpen(true);
    }
    setPromoCode("");
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {notification}
        </Alert>
      </Snackbar>
      <Typography variant="h6" mb={1}>
        Enter Promo Code
      </Typography>
      <Typography variant="body2" color="secondary">
        If you received a promo code to waive your $5 activation fee you can
        enter it now.
      </Typography>
      <Box my={4}>
        <Typography variant="body2" fontWeight={600} sx={{ mb: 0.5 }}>
          Promo Code
        </Typography>
        <StyledTextField
          value={promoCode}
          placeholder="Enter Promo Code..."
          sx={{ width: "100%" }}
          onChange={(e) => setPromoCode(e.target.value)}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <StyledButton variant="text" fullWidth onClick={handleSkip}>
            Skip for now
          </StyledButton>
        </Grid>
        <Grid item md={6} xs={12}>
          <StyledButton
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            disabled={promoCode === ""}
          >
            Submit
          </StyledButton>
        </Grid>
      </Grid>
    </>
  );
}

export default PromoCode;
