import jwt_decode from "jwt-decode";

export const setAuthorizationToken = (token: string) => {
  localStorage.setItem("authorization_token", token);
};

export const isAccessTokenExpired = (accessToken: string) => {
  try {
    const decodedToken = jwt_decode<{ exp: number }>(accessToken);
    return decodedToken.exp < Date.now() / 1000;
  } catch (err) {
    return true; // Token is invalid or expired
  }
};

export const convertTimestampToDateTime = (timestamp: number) => {
  const month_names = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date_ob = new Date(timestamp * 1000);
  const year = date_ob.getFullYear();
  const month = month_names[date_ob.getMonth()];
  const date = ("0" + date_ob.getDate()).slice(-2);
  const hours = ("0" + date_ob.getHours()).slice(-2);
  const minutes = ("0" + date_ob.getMinutes()).slice(-2);
  const result = `${month} ${date}, ${year} ${hours}:${minutes}`;
  return result;
};
