import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Logo from "../assets/images/logo.svg";
import {
  Alert,
  AlertColor,
  Box,
  Link,
  Snackbar,
  Typography,
} from "@mui/material";
import { StyledButton, StyledTextField } from "../components/styled";
import { AuthService } from "../api/auth/auth.service";

function ResetPassword() {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState({
    value: "",
    helperText: "",
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState<{ type: AlertColor; message: string }>(
    {
      type: "success",
      message: "",
    }
  );

  const handleClose = () => {
    setOpen(false);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const passwordPattern =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!])(?!.*\s).{9,}$/;
    if (!passwordPattern.test(password.value)) {
      setPassword((prev) => ({
        ...prev,
        helperText: "Password is not valid.",
      }));
      return;
    }
    if (password.value !== "" && password.value !== confirmPassword) {
      setPassword((prev) => ({
        ...prev,
        helperText:
          "Confirmation password doesn't match. Please input your password again.",
      }));
      return;
    }
    try {
      const data = await AuthService.resetPassword(
        queryParameters.get("token") || "",
        password.value
      );
      setMessage({ type: "success", message: data });
      setPassword({ value: "", helperText: "" });
      setConfirmPassword("");
      setOpen(true);
      navigate("/signin");
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.log(err);
      setMessage({ type: "error", message: err.response.data });
      setOpen(true);
    }
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={message.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message.message}
        </Alert>
      </Snackbar>
      <img src={Logo} width={200} alt="logo" style={{ display: "block" }} />
      <Typography variant="h5" mt={2}>
        Reset Password
      </Typography>
      <Typography mt={1}>Enter your new password below to reset</Typography>
      <Box my={4}>
        <form>
          <Box mb={2}>
            <Typography variant="body2" fontWeight={600} mb={0.5}>
              New Password
            </Typography>
            <StyledTextField
              type="password"
              id="new-password"
              name="new-password"
              placeholder="New Password"
              variant="outlined"
              fullWidth
              value={password.value}
              error={!!password.helperText}
              helperText={password.helperText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPassword({ value: e.target.value, helperText: "" })
              }
            />
          </Box>
          <Box mb={2}>
            <Typography variant="body2" fontWeight={600} mb={0.5}>
              Confirm Password
            </Typography>
            <StyledTextField
              type="password"
              id="confirm-password"
              name="confirm-password"
              placeholder="Confirm Password"
              variant="outlined"
              fullWidth
              value={confirmPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setConfirmPassword(e.target.value)
              }
            />
          </Box>
          <ul
            style={{
              color: "#6B596B",
              fontSize: "12px",
              paddingLeft: "20px",
              marginTop: "4px",
            }}
          >
            <li>Must be more than 8 characters</li>
            <li>
              Must include at least one uppercase letter, number and special
              character
            </li>
          </ul>
          <Box my={4}>
            <StyledButton
              type="submit"
              variant="contained"
              fullWidth
              onClick={handleSubmit}
            >
              Reset Password
            </StyledButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="body2" mr={3}>
              Do you want to sign in now?
            </Typography>
            <Link
              href="/signin"
              sx={{ textDecoration: "none", fontSize: "14px", fontWeight: 600 }}
            >
              Sign In
            </Link>
          </Box>
        </form>
      </Box>
    </>
  );
}

export default ResetPassword;
