import { useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Link,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { StyledButton } from "../../components/styled";

import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import { UserData } from "../../types/auth";
import { ProfileService } from "../../api/profile/profile.service";
import { StripeService } from "../../api/stripe/stripe.service";

function TwoFactor() {
  const navigate = useNavigate();
  const user = useSelector<AppState, UserData | null>(
    ({ account }) => account.user
  );
  const [code, setCode] = useState(new Array(6).fill(""));
  const [open, setOpen] = useState(false);
  const [codeResent, setCodeResent] = useState(false);
  const [processing, setProcessing] = useState(false);

  const inputRef = useRef<HTMLElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleContinue = async () => {
    try {
      setProcessing(true);
      const data = await verifySMSCode();
      if (data && data.status === "approved") {
        await StripeService.createStripeAccount();
        navigate("/onboarding/industry-and-job");
      } else {
        setOpen(true);
        setCode(new Array(6).fill(""));
      }
      setProcessing(false);
    } catch (err) {
      console.log(err);
      setProcessing(false);
    }
  };
  const sendSMSVerification = async () => {
    try {
      setCodeResent(true);
      await ProfileService.sendSmsCode(user?.phoneNumber || "");
      setTimeout(() => {
        setCodeResent(false);
      }, 10000);
    } catch (err) {
      console.log(err);
      setCodeResent(false);
    }
  };
  const verifySMSCode = async () => {
    const codeStr = code.join("");
    if (codeStr.length < 6) return;
    try {
      const response = await ProfileService.verifySmsCode(codeStr);
      return response;
    } catch (err) {
      console.log(err);
    }
    return null;
  };

  const handleChange = (value: string, index: number) => {
    if (value === " " || isNaN(Number(value))) {
      if (inputRef?.current) {
        const element = inputRef.current.children[index].querySelector(
          "input"
        ) as HTMLInputElement;
        element.value = "";
      }
      return;
    }
    setCode((prev) => {
      const t = [...prev];
      t[index] = value;
      return t;
    });
    if (inputRef?.current && index < 5 && value !== "") {
      const element = inputRef.current.children[index + 1].querySelector(
        "input"
      ) as HTMLInputElement;
      element.focus();
    }
  };
  const handlePaste = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    e: any
  ) => {
    e.preventDefault();
    let clipboard = await navigator.clipboard.readText();
    clipboard = clipboard.slice(0, 6);
    const arr = Array.from(clipboard).filter(
      (x) => Number(x) > -1 && Number(x) < 10
    );
    arr.forEach((elem: string, index: number) => {
      if (inputRef?.current) {
        const element = inputRef.current.children[index].querySelector(
          "input"
        ) as HTMLInputElement;
        element.value = elem;
      }
      setCode((prev) => {
        const t = [...prev];
        t[index] = elem;
        return t;
      });
    });
  };

  useEffect(() => {
    if (code.filter((x) => parseInt(x) > -1 && parseInt(x) < 10).length === 6) {
      handleContinue();
    }
  }, [code]);

  useEffect(() => {
    if (inputRef?.current) {
      const element = inputRef.current.children[0].querySelector(
        "input"
      ) as HTMLInputElement;
      element.focus();
    }
  }, []);

  return (
    <Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          severity="error"
          sx={{ width: "100%" }}
        >
          Verification code is incorrect!
        </Alert>
      </Snackbar>
      <StyledButton
        variant="outlined"
        startIcon={<ArrowBackIcon sx={{ width: 16, height: 16 }} />}
        href="/onboarding/personal-information"
        sx={{
          padding: "10px 16px",
          borderRadius: "12px",
          fontSize: 14,
          lineHeight: "20px",
          border: "1px solid #161215",
        }}
      >
        Back
      </StyledButton>
      <Typography variant="h6" mt={3} mb={1}>
        Verification Code
      </Typography>
      <Typography variant="body2" color="secondary">
        We sent a 6 digit code to your mobile phone, enter it below to continue.
      </Typography>
      <Box
        ref={inputRef}
        sx={{
          my: 4,
          py: 8,
          display: "flex",
          justifyContent: { md: "center", xs: "space-between" },
        }}
      >
        {[...Array(6)].map((_value, index) => (
          <TextField
            key={index}
            type="number"
            size="small"
            value={code[index]}
            sx={{
              mx: { md: 1.5, xs: 0 },
              width: "44px",
              [`& fieldset`]: {
                borderRadius: "12px",
              },
            }}
            inputProps={{
              style: { textAlign: "center" },
              maxLength: 1,
            }}
            onChange={(e) => handleChange(e.target.value, index)}
            onPaste={handlePaste}
          />
        ))}
      </Box>
      <StyledButton
        ref={buttonRef}
        variant="contained"
        fullWidth
        onClick={handleContinue}
        disabled={processing}
      >
        Continue
      </StyledButton>
      <Typography
        variant="body2"
        sx={{ color: "#64748B", textAlign: "center", mt: 3, mb: 1 }}
      >
        {"Didn’t receive a code?"}
      </Typography>
      <Link
        sx={{
          display: "block",
          textDecoration: "none",
          p: "10px 8px",
          textAlign: "center",
          fontSize: 14,
          fontWeight: 600,
          lineHeight: "20px",
          cursor: "pointer",
          pointerEvents: codeResent ? "none" : "auto",
          color: codeResent ? "#64748B" : "inherit",
        }}
        onClick={sendSMSVerification}
      >
        Resend code
      </Link>
    </Box>
  );
}

export default TwoFactor;
