import { Box, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import checkMark from "../assets/images/check.svg";
import { StyledButton } from "../components/styled";

function TransferSuccess() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "calc(100vh - 106px)",
        }}
      >
        <img src={checkMark} width={72} height={72} alt="check" />
        <Typography
          variant="h5"
          sx={{ marginTop: "40px", marginBottom: "16px" }}
        >
          Transfer Successful
        </Typography>
        <Typography
          color="secondary"
          sx={{ fontSize: 20, textAlign: "center" }}
        >
          Your tips will be in your account in as little as 20 minutes.
        </Typography>
      </Box>
      <StyledButton
        variant="outlined"
        startIcon={<ArrowBackIcon sx={{ fontSize: 16 }} />}
        fullWidth
        sx={{
          fontSize: 16,
          fontWeight: 500,
          lineHeight: "24px",
        }}
        href="/"
      >
        Back to Dashboard
      </StyledButton>
    </>
  );
}

export default TransferSuccess;
